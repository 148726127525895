import { useDispatch, useSelector } from "react-redux";
import { setCartConfirmation } from "store/customer/customerSlice";

const StepTwoServices = ({ setBookingDetails }) => {
  const dispatch = useDispatch();
  const confirmationType = useSelector((state) => state.customer.cartDetails.confirmation);

  const handleConfirmationChange = (type) => {
    dispatch(setCartConfirmation(type));
    setBookingDetails((prev) => ({ ...prev, confirmation: type }));
  };

  return (
    <div className="step-two-info">
      <h2 className="title-header-step mb-3">اختيار نوع الحجز</h2>
      <div className="all-info-step pt-3">
        <div className="confirm-one mt-3">
          <div className="form-check d-flex  align-items-center  gap-3">
            <input
              className="form-check-input"
              type="radio"
              name="confirmed"
              id="confirmed"
              checked={confirmationType === "confirmed"}
              onChange={() => handleConfirmationChange("confirmed")}
            />
            <label className="form-check-label title-text" htmlFor="confirmed">
              مؤكد
            </label>
          </div>

          <label className="form-check-label content-label" htmlFor="confirmed">
            <div className="header-top d-flex align-items-center gap-2">
              <span className="icon-a">*</span> <h2 className="title-text">الحجز المؤكد</h2>
            </div>
            <p className="text" style={{ marginTop: "10px", minWidth: "800px" }}>
              الحجز نهائي ولا يمكن محاولة الحجز في نفس الميعاد مرة أخرى.
            </p>
          </label>
        </div>

        <div className="confirm-one mt-3">
          <div className="form-check d-flex  align-items-center  gap-3">
            <input
              className="form-check-input"
              type="radio"
              name="not_confirmed"
              id="not_confirmed"
              onChange={() => handleConfirmationChange("not_confirmed")}
              checked={confirmationType === "not_confirmed"}
            />
            <label className="form-check-label title-text" htmlFor="not_confirmed">
              غير مؤكد
            </label>
          </div>
          <label className="form-check-label content-label" htmlFor="not_confirmed">
            <div className="header-top d-flex align-items-center gap-2">
              <span className="icon-a">*</span> <h2 className="title-text">الحجز الغير مؤكد</h2>
            </div>
            <p className="text" style={{ minWidth: "800px" }}>
              الحجز غير نهائي ويمكن ان يتم الحجز في نفس الميعاد اذا كان هناك حجز مؤكد في نفس
              الميعاد.
            </p>
          </label>
        </div>
      </div>
    </div>
  );
};

export default StepTwoServices;

import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentDiscount, setPaymentTax, setPaymentTotal } from "store/vendor/vendorSlice";
import CardsHeaderPos from "./CardsHeaderPos";
import CardsProducts from "./CardsProducts/CardsProducts";
import DetailsOrder from "./DetailsOrder/PosCartDetails";
import "./Pos.css";

const Pos = () => {
  const dispatch = useDispatch();
  const cartDetails = useSelector((state) => state.vendor.cartDetails);

  const cartServices = cartDetails.services;
  const offer = cartDetails.offer;

  const calculations = useMemo(() => {
    const total = cartServices.reduce((acc, service) => acc + parseFloat(service.price), 0);
    const paymentDetails = [{ title: "المجموع", price: total.toFixed(2) }];
    let totalDiscount = 0;

    if (offer) {
      const offerDiscount = (total * parseFloat(offer.offerDiscount)) / 100;
      totalDiscount = offerDiscount;

      paymentDetails.push({
        title: `خصم العرض (${offer.offerDiscount.toString()}%)`,
        price: offerDiscount.toFixed(2),
      });
    }

    const finalTotal = total - totalDiscount;
    paymentDetails.push({
      title: "الأجمالي",
      price: finalTotal.toFixed(2),
      newClass: "border-top border-2",
    });

    dispatch(setPaymentTotal(finalTotal));
    dispatch(setPaymentTax(0));
    dispatch(setPaymentDiscount(totalDiscount));
    return paymentDetails;
  }, [cartServices, offer, dispatch]);

  if (cartDetails.length === 0) return null;
  return (
    <>
      <HelmetInfo titlePage={"نقطة البيع"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          titleInfoPage={"نقطة البيع"}
          routeHomePage={"/dashboard/vendor/homedashboard"}
        />
      </div>

      <div className="all-pos">
        <div className="row g-3">
          <div className="col-12 col-md-8 col-lg-12 col-xl-8">
            <div className="right-info-pos">
              {/* Header of POS  (info about daily bookings, cash, online) */}
              <CardsHeaderPos />
              {/* List of items (services/offers) */}
              <CardsProducts />
            </div>
          </div>
          {/* Booking Order Details */}
          <div className="col-12 col-md-4 col-lg-12 col-xl-4">
            <DetailsOrder calculations={calculations} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Pos;

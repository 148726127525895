import { useCallback } from "react";
import { useSelector } from "react-redux";

const ButtonCounter = ({ serviceId, isOffer, offerId, addToCart, removeCart }) => {
  const cartOffer = useSelector((state) => state.vendor.cartDetails.offer);
  const cartServices = useSelector((state) => state.vendor.cartDetails.services);

  const isInCart = () => {
    if (!isOffer && cartServices.some((svc) => svc.serviceId === serviceId)) return true;
    if (isOffer && offerId && cartOffer?.offerId === offerId) return true;
    return false;
  };

  const handleClick = useCallback(() => {
    if (isInCart()) {
      removeCart();
    } else {
      addToCart();
    }
  }, [isInCart, addToCart, removeCart]);

  return (
    <div className="d-flex align-items-center">
      <div className="main-btn main-outline-btn py-1 " onClick={handleClick}>
        {isInCart() ? "ازالة" : "اضافة"}
      </div>
    </div>
  );
};

export default ButtonCounter;

import { faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import SearchHeaderPage from "Dashboard/Components/SearchHeaderPage/SearchHeaderPage";
import IconsTable from "Dashboard/Components/UiDashboard/IconsTable/IconsTable";
import Pagination from "Dashboard/Components/UiDashboard/PaginationSelect/Pagination";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import Toggleswitch from "Dashboard/Components/UiDashboard/Toggleswitch/Toggleswitch";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deletePromo, getPromos, updatePromo } from "store/general/generalActions";
import { formatDateToArabic } from "utlis";
import "./DiscountCodes.css";

const DiscountCodes = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [currentPageItemCounts, setCurrentPageItemCounts] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [promos, setPromos] = useState(null);

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.general.isLoading);

  const params = useMemo(() => {
    return {
      with: ["user"],
      per_page: limit,
      page: currentPage,
      deleted: "undeleted",
      resource: "all",
      resource_columns: ["id", "code"],
      columns: ["code", "created_by"],
      operand: ["like", "="],
      column_values: [`%${search}%`, user?.id],
    };
  }, [limit, currentPage, search, user?.id]);

  const fetchPromos = (params) => {
    dispatch(getPromos(params))
      .then(unwrapResult)
      .then((res) => {
        setPromos(res.data.data);
        setCurrentPageItemCounts(res.data.data?.length || 0);
        setTotalItemsCount(res.data.total || 0);
        setTotalPagesCount(res.data.links.length - 2 || 0);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    fetchPromos(params);
  }, [limit, currentPage, search, dispatch]);

  const handleDeletePromo = (id) => {
    dispatch(deletePromo(id))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم الحذف بنجاح.");
        fetchPromos(params);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleToggleSwitch = (newValue, index) => {
    const promoDetails = { ...promos[index] };
    delete promoDetails.user;
    delete promoDetails.updated_at;
    dispatch(updatePromo({ ...promoDetails, is_active: `${newValue ? "1" : "0"}` }))
      .then(unwrapResult)
      .then(() => {
        const updatedData = [...promos];
        updatedData[index].is_active = newValue ? "1" : "0";
        toast.success("تم التعديل بنجاح.");
        setPromos(updatedData);
        fetchPromos(params);
      })
      .catch((error) => {
        console.log({ error });
        if (error.errors?.start_date) {
          const message = "تم تجاوز تاريخ الانتهاء. احذف الكود واعد الأنشاء";
          toast.error(message);
        } else {
          toast.error("حدث خطأ ما");
        }
      });
  };

  return (
    <>
      <HelmetInfo titlePage={"اكواد الخصم"} />
      <div data-aos="fade-right">
        <HeaderPageInfo
          title={"اكواد الخصم"}
          isShowLeftContent={true}
          contentPageLeft={
            <SearchHeaderPage
              isButtonActive={true}
              typeIcon={faPlus}
              textButton={"اضافة كود جديد"}
              functionButton={() => {
                navigate(`${"editAddDiscountCode/id"}`);
              }}
              onSearchChange={setSearch}
            />
          }
        />
      </div>

      <div className="table-content mt-3 table-content-2" data-aos="fade-up">
        {isLoading ? (
          <Loader />
        ) : (
          <TableDataInfo
            titleTableHeader={false}
            isHashTrue={false}
            hashId={false}
            dateThead={
              <>
                <th scope="col">كود الخصم</th>
                <th scope="col">تاريخ الأنشاء</th>
                <th scope="col">الأستخدام</th>
                <th scope="col">صلاحية الكود من</th>
                <th scope="col">صلاحية الكود الي</th>
                <th scope="col">تفعيل/الغاء</th>
                <th scope="col">الاجراءات</th>
              </>
            }
            dataTbody={
              <>
                {promos?.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.code}</td>
                      <td>{formatDateToArabic(item.created_at)}</td>
                      <td>{item.uses}</td>
                      <td>{item.start_date}</td>
                      <td>{item.end_date}</td>
                      <td>
                        <Toggleswitch
                          switchId={item.id}
                          nameSwitch={`promo-${item.id}`}
                          value={item.is_active === "1" ? true : false}
                          onChange={(newValue) => {
                            handleToggleSwitch(newValue, index);
                          }}
                        />
                      </td>
                      <td>
                        <IconsTable
                          typeIcon1={faEye}
                          functionEye={() => {
                            navigate(`discountDetails/${item.id}`);
                          }}
                          isDeleteIcon={true}
                          functionDelete={() => {
                            handleDeletePromo(item.id);
                          }}
                          typeIcon3={faTrash}
                        />
                      </td>
                    </tr>
                  );
                })}
              </>
            }
          />
        )}
      </div>
      <Pagination
        limit={limit}
        setLimit={(val) => setLimit(val)}
        currentPage={currentPage}
        setCurrentPage={(val) => setCurrentPage(val)}
        limitOptions={[5, 10, 20, 30, 40]}
        currentPageItemsCount={currentPageItemCounts}
        totalItemsCount={totalItemsCount}
        totalPagesCount={totalPagesCount}
      />
    </>
  );
};

export default DiscountCodes;

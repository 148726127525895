import { createAsyncThunk } from "@reduxjs/toolkit";
import Admin from "Apis/admin";
import axiosClient from "Apis/axiosService";

export const getVendors = createAsyncThunk(
  "admin/getVendors",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/admin/v1/vendor_report_list", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getVendor = createAsyncThunk(
  "admin/getVendor",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/vendor_report_show/${id}`, {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAdminVendors = createAsyncThunk(
  "admin/getAdminVendors",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/admin/v1/vendors");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createVendor = createAsyncThunk(
  "admin/createVendor",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/admin/v1/vendors", data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateVendor = createAsyncThunk(
  "admin/updateVendor",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`/admin/v1/vendors/${id}?_method=PUT`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getPackages = createAsyncThunk(
  "admin/packages",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/admin/v1/packages", { params });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addPackage = createAsyncThunk(
  "admin/addPackage",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/admin/v1/packages", {
        ...values,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getPackage = createAsyncThunk("admin/package", async (id, { rejectWithValue }) => {
  try {
    const response = await axiosClient.get(`/admin/v1/packages/${id}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updatePackage = createAsyncThunk(
  "admin/updatePackage",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`/admin/v1/packages/${values.id}`, values, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePackage = createAsyncThunk(
  "admin/deletePackage",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.delete(`/admin/v1/packages/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getServices = createAsyncThunk(
  "admin/services",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/service_report_list`, {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAdminServices = createAsyncThunk(
  "admin/getAdminServices",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/admin/v1/services", { params });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getCustomersListThunk = createAsyncThunk(
  "admin/getCustomersList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/admin/v1/customer_report_list", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getService = createAsyncThunk("admin/service", async (id, { rejectWithValue }) => {
  try {
    const response = await axiosClient.get(`/admin/v1/service_report_show/${id}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getAdminServiceDetails = createAsyncThunk(
  "admin/getAdminServiceDetails",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/services/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteAdminService = createAsyncThunk(
  "admin/deleteAdminService",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.delete(`/admin/v1/services/${id}`, {
        data: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getCustomerDataThunk = createAsyncThunk(
  "admin/getCustomerData",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Admin.getCustomerData(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSubscriptions = createAsyncThunk(
  "admin/getSubscriptions",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("admin/v1/subscription_report_list", {
        params,
      });
      console.log("--- response ----", response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getSubscription = createAsyncThunk(
  "admin/getSubscription",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`admin/v1/subscription_report_show/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBookings = createAsyncThunk(
  "admin/getBookings",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("admin/v1/booking_report_list", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBooking = createAsyncThunk("admin/booking", async (id, { rejectWithValue }) => {
  try {
    const response = await axiosClient.get(`/admin/v1/booking_report_show/${id}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getHomeData = createAsyncThunk("admin/homeReports", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosClient.get("/admin/v1/home_totals");
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getBookingCountChart = createAsyncThunk(
  "admin/bookingCountChart",
  async (period, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/booking_count_chart?filter=${period}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBookingTotalChart = createAsyncThunk(
  "admin/bookingTotalChart",
  async (period, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/booking_total_chart?filter=${period}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getOffers = createAsyncThunk(
  "admin/getOffers",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("admin/v1/offer_report_list", {
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getOffer = createAsyncThunk("admin/getOffer", async (id, { rejectWithValue }) => {
  try {
    const response = await axiosClient.get(`/admin/v1/offer_report_show/${id}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getAdminOffer = createAsyncThunk(
  "admin/getAdminOffer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/offers/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateAdminOffer = createAsyncThunk(
  "admin/updateAdminOffer",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`/admin/v1/offers/${values.id}`, values, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteAdminOffer = createAsyncThunk(
  "admin/deleteAdminOffer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.delete(`/admin/v1/offers/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getPaymentSettings = createAsyncThunk(
  "admin/getPaymentSettings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/admin/v1/payment_settings");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updatePaymentSettings = createAsyncThunk(
  "admin/updatePaymentSettings",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/admin/v1/payment_settings", {
        ...values,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getNotificationSettings = createAsyncThunk(
  "admin/getNotificationSettings",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/notification_settings`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateNotificationSettings = createAsyncThunk(
  "admin/updateNotificationSettings",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`/admin/v1/notification_settings`, values, {
        // params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBookingDifference = createAsyncThunk(
  "admin/getBookingDifference",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/service_settings`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateBookingDifference = createAsyncThunk(
  "admin/updateBookingDifference",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`/admin/v1/service_settings`, values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getTaxSettings = createAsyncThunk(
  "admin/getTaxSettings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/tax_settings`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateTaxSettings = createAsyncThunk(
  "admin/updateTaxSettings",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`/admin/v1/tax_settings`, values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getHelpCenter = createAsyncThunk(
  "admin/getHelpCenter",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/admin/v1/contact_messages", { params });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getFAQs = createAsyncThunk("admin/getFAQs", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosClient.get("admin/v1/f_a_q_s", {
      params,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getFAQ = createAsyncThunk("admin/getFAQ", async (id, { rejectWithValue }) => {
  try {
    const response = await axiosClient.get(`/admin/v1/f_a_q_s/${id}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
export const updateFAQ = createAsyncThunk(
  "admin/UpdateFAQ",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`/admin/v1/f_a_q_s/${values.id}`, values, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addFAQ = createAsyncThunk("admin/addFAQ", async (values, { rejectWithValue }) => {
  try {
    const response = await axiosClient.post(`/admin/v1/f_a_q_s`, values);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getBookingStatisticsMonth = createAsyncThunk(
  "admin/getBookingStatisticsMonth",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/booking_count_with_month_chart?year=2024`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBookingStatisticsWeek = createAsyncThunk(
  "admin/getBookingStatisticsWeek",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/booking_count_last_week_chart`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getRegisterStatisticsMonth = createAsyncThunk(
  "admin/getRegisterStatisticsMonth",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/register_count_with_month_chart?year=2024`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const statiticsLastBookings = createAsyncThunk(
  "admin/statiticsLastBookings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/last_bookings`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const statiticsLastCustomers = createAsyncThunk(
  "admin/statiticsLastCustomers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/last_customers`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getUsers = createAsyncThunk("admin/getUsers", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosClient.get("/admin/v1/users", {
      params,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateVendorStatus = createAsyncThunk(
  "admin/updateVendorStatus",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`/admin/v1/activate_vendor`, {
        vendor_id: values.vendor_id,
        is_active: values.is_active,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getRoles = createAsyncThunk("admin/getRoles", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosClient.get("/admin/v1/roles", {
      params,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getRoleDetails = createAsyncThunk(
  "admin/getRoleDetails",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/roles/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateRole = createAsyncThunk(
  "admin/updateRole",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`/admin/v1/roles/${values.id}`, values, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteRole = createAsyncThunk("admin/deleteRole", async (id, { rejectWithValue }) => {
  try {
    const response = await axiosClient.delete(`/admin/v1/roles/${id}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const addRole = createAsyncThunk("admin/addRole", async (values, { rejectWithValue }) => {
  try {
    const response = await axiosClient.post("/admin/v1/roles", values);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteCustomerThunk = createAsyncThunk("admin/deleteCustomer", async (id) => {
  const response = await axiosClient.delete(`/admin/v1/users/${id}`);
  return response.data;
});

export const toggleCustomerStatusThunk = createAsyncThunk(
  "admin/toggleCustomerStatus",
  async (values) => {
    const response = await axiosClient.post(`/admin/v1/users/${values.id}`, {
      _method: "PUT",
      ...values,
    });
    return response.data;
  },
);

export const updateService = createAsyncThunk(
  "admin/updateService",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`admin/v1/services/${data.id}`, data, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const addService = createAsyncThunk(
  "admin/addService",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/admin/v1/services", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getBranches = createAsyncThunk(
  "admin/getBranches",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/admin/v1/branches_report_list", {
        params,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAdminBranches = createAsyncThunk(
  "admin/getAdminBranches",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/admin/v1/branches", {
        params,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAdminBranch = createAsyncThunk(
  "admin/getAdminBranch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/branches/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateAdminBranch = createAsyncThunk(
  "admin/updateAdminBranch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`/admin/v1/branches/${data.id}`, data, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateAdminBranchFormData = createAsyncThunk(
  "admin/updateAdminBranchFormData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`/admin/v1/branches/${data.id}`, data.data, {
        params: { _method: "PUT" },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const addBranchAdmin = createAsyncThunk(
  "admin/addBranchAdmin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/admin/v1/branches", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteAdminBranch = createAsyncThunk(
  "admin/deleteAdminBranch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.delete(`/admin/v1/branches/${id}`, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getSections = createAsyncThunk(
  "admin/getSections",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/admin/v1/sections_report_list", {
        params,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getEmployees = createAsyncThunk(
  "admin/getEmployees",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/admin/v1/employee_report_list", {
        params,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getEmployee = createAsyncThunk(
  "admin/getEmployee",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/employee_report_show/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAdminEmployees = createAsyncThunk(
  "admin/getAdminEmployees",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/admin/v1/employees", { params });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAdminEmployee = createAsyncThunk(
  "admin/getAdminEmployee",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/admin/v1/employees/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateAdminEmployee = createAsyncThunk(
  "admin/updateAdminEmployee",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`/admin/v1/employees/${data.id}`, data, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteAdminEmployee = createAsyncThunk(
  "admin/deleteAdminEmployee",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.delete(`/admin/v1/employees/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const addAdminEmployee = createAsyncThunk(
  "admin/addAdminEmployee",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/admin/v1/employees", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateEmployee = createAsyncThunk(
  "admin/updateEmployee",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post(`/admin/v1/employees/${data.id}`, data, {
        params: { _method: "PUT" },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getVendorsReport = createAsyncThunk(
  "admin/getVendorsReport",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/admin/v1/vendor_report_list");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getClients = createAsyncThunk(
  "admin/getClients",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/admin/v1/customer_report_list", { params });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

import HomeInfo from "Components/HomeInfo/HomeInfo";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearchDetails } from "store/customer/customerActions";
import { extractOffers } from "utlis";

const Home = () => {
  const storeServicesList = useSelector((state) => state.customer.servicesList);
  const storeServiceProviders = useSelector((state) => state.customer.serviceProviders);

  const [serviceProviders, setServiceProviders] = useState([]); // for home page display
  const [offers, setOffers] = useState([]); // for home page display
  const [isLoading, setIsLoading] = useState(false); // for home page display

  useEffect(() => {
    if (storeServicesList.length && storeServiceProviders.length) {
      setServiceProviders(storeServiceProviders);
      setOffers(extractOffers(storeServiceProviders, storeServicesList));
    }
  }, [storeServicesList, storeServiceProviders]);

  const dispatch = useDispatch();

  const searchProviders = useCallback(
    async (params) => {
      setIsLoading(true);
      if (params.search_text !== "") {
        const result = await dispatch(getSearchDetails(params)).unwrap();
        const vendors = result.data.vendors.data.filter((vendor) => vendor.is_active === "1");
        const services = result.data.services.data.filter((service) => service.is_active === "1");
        setServiceProviders(vendors);
        setOffers(extractOffers(vendors, services));
      } else {
        setServiceProviders(storeServiceProviders);
        setOffers(extractOffers(storeServiceProviders, storeServicesList));
      }
      setIsLoading(false);
    },
    [dispatch],
  );

  return (
    <div>
      <HomeInfo
        serviceProviders={serviceProviders}
        offers={offers}
        searchProviders={searchProviders}
        isLoading={isLoading}
      />{" "}
    </div>
  );
};

export default Home;

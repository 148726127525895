import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  setCartDiscount,
  setCartNotes,
  setCartPaymentWay,
  setCartPromocode,
  setCartSubTotalPrice,
  setCartTotalPrice,
} from "store/customer/customerSlice";

import { getPromos } from "store/general/generalActions";
import { useDebounce } from "use-debounce";
import { getFormattedDateForBooking } from "utlis";
import img from "../../../../assets/images/booking/01.png";
import iconCalender from "../../../../assets/images/icons/calendar.svg";
import iconLocation from "../../../../assets/images/icons/location.svg";
import { ProviderPolicy } from "./ProviderPolicy";

const buttons = [
  { id: "online", label: "الدفع اون لاين", value: "online" },
  { id: "cash", label: "الدفع عند الخدمة", value: "cash" },
];

const StepFourServices = ({ bookingDetails, setBookingDetails, paymentMethod }) => {
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState(null);
  const [notes, setNotes] = useState("");
  const cartDetails = useSelector((state) => state.customer.cartDetails);
  const [promocode, setPromocode] = useState("");
  const [promoDiscount, setPromoDiscount] = useState(0);
  const [appliedPromo, setAppliedPromo] = useState(null);
  const [priceAfterDiscount, setPriceAfterDiscount] = useState(0);

  const totalPrice =
    cartDetails?.services?.reduce((acc, service) => acc + parseInt(service.price), 0) || 0;

  useEffect(() => {
    setPriceAfterDiscount(totalPrice);
  }, [totalPrice]);
  const [debouncedNotes] = useDebounce(notes, 1000);
  useEffect(() => {
    setBookingDetails((prev) => ({
      ...prev,
      notes: debouncedNotes,
    }));
  }, [debouncedNotes, setBookingDetails]);

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
    dispatch(setCartNotes(e.target.value));
  };

  useEffect(() => {
    const isConfirmed = cartDetails?.confirmation === "confirmed";
    if (isConfirmed) {
      const onlineButton = buttons.find((button) => button.value === "online");
      if (onlineButton) handleButtonClick(onlineButton);
    } else {
      if (paymentMethod === "both") {
        handleButtonClick(buttons[0]);
      } else if (paymentMethod === "cash" || paymentMethod === "online") {
        const supportedButton = buttons.find((button) => button.value === paymentMethod);
        if (supportedButton) handleButtonClick(supportedButton);
      }
    }
  }, [paymentMethod, cartDetails?.confirmation]);

  const handleButtonClick = (button) => {
    setActiveButton(button.id);
    setBookingDetails((prev) => ({
      ...prev,
      payment_way: button.value,
    }));
    dispatch(setCartPaymentWay(button.value));
  };

  const renderPaymentMethod = () => {
    if (paymentMethod === "cash" || paymentMethod === "online") {
      const supportedButton = buttons.find((button) => button.value === paymentMethod);
      if (supportedButton) {
        return (
          <div className="button-check-pay pt-3 border-bottom pb-3">
            <div className="button-one mb-3 d-flex justify-content-between align-items-center active">
              <div className="text-check">{supportedButton.label}</div>
              <div className="icon-check">
                <FontAwesomeIcon icon={faCheck} />
              </div>
            </div>
          </div>
        );
      }
    }

    const isConfirmed = cartDetails?.confirmation === "confirmed";
    return (
      <div className="button-check-pay pt-3 border-bottom pb-3">
        {buttons.map((button) => {
          if (isConfirmed && paymentMethod === "both") {
            return button.value === "online" ? (
              <div
                key={button.id}
                className="button-one mb-3 d-flex justify-content-between align-items-center active">
                <div className="text-check">{button.label}</div>
                <div className="icon-check">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              </div>
            ) : null;
          }

          return (
            <div
              className={`button-one mb-3 d-flex justify-content-between align-items-center ${
                activeButton === button.id ? "active" : ""
              }`}
              key={button.id}
              onClick={() => handleButtonClick(button)}>
              {button.label}
              {activeButton === button.id && (
                <div className="icon-check">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const handleApplyPromocode = async () => {
    try {
      const param = {
        with: [],
        limit: 10,
        offset: 0,
        sort: "DESC",
        deleted: "undeleted",
        field: "id",
        resource: "all",
        columns: ["is_active", "code"],
        operand: ["=", "like"],
        column_values: [1, `%${promocode}%`],
      };
      const promos = await dispatch(getPromos(param)).unwrap();

      if (promos.data.data && promos.data.data.length > 0) {
        const promoCode = promos.data.data[0];
        if (promoCode.is_active === "1") {
          // TODO: check if the promo is valid for this vendor

          const currentDate = new Date();
          const formatDate = (dateStr) => {
            const [day, month, year] = dateStr.split("-");
            return `${year}-${month}-${day}`;
          };

          const startDate = new Date(formatDate(promoCode.start_date));
          const endDate = new Date(formatDate(promoCode.end_date));

          if (currentDate >= startDate && currentDate <= endDate) {
            setAppliedPromo(promoCode);
            if (promoCode.discount_type === "fixed") {
              setPromoDiscount(promoCode.value);
            } else {
              const discountAmount = (totalPrice * promoCode.value) / 100;
              setPromoDiscount(discountAmount);
            }
          } else {
            toast.error("كود الخصم منتهي الصلاحية");
          }
        } else {
          toast.error("كود الخصم غير نشط");
        }
      } else {
        toast.error("كود الخصم غير صالح");
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleRemovePromocode = () => {
    setAppliedPromo(null);
    setPromoDiscount(0);
    setPromocode("");
    dispatch(setCartPromocode(null));
    dispatch(setCartDiscount(0));
    setBookingDetails((prev) => ({
      ...prev,
      discount: 0,
    }));
  };

  useEffect(() => {
    const finalPrice = Math.max(0, priceAfterDiscount - promoDiscount);
    dispatch(setCartTotalPrice(finalPrice));
    dispatch(setCartSubTotalPrice(priceAfterDiscount));

    if (appliedPromo && appliedPromo !== "" && appliedPromo !== null) {
      dispatch(setCartPromocode(appliedPromo.id));
      dispatch(setCartDiscount(promoDiscount));
      setBookingDetails((prev) => ({
        ...prev,
        discount: promoDiscount,
      }));
    }
  }, [promoDiscount, priceAfterDiscount, appliedPromo, dispatch]);

  return (
    <div className="step-four-content">
      <div className="row g-3">
        <div className="col-12 col-lg-8">
          <div className="right-info-content-step">
            <div className="header-one-step">
              <h2 className="title size-text-color-dark">مراجعة الطلب والدفع</h2>
              <p className="text size-text-color-gray">
                لن يتم تحصيل رسوم منك الآن، سيتم تحصيل الدفعة في المتجر بعد موعدك
              </p>
            </div>

            <div className="right-main-info pt-4">
              <h2 className="title size-text-color-dark pb-3">اختر طريقة الدفع المناسبة لك</h2>

              {renderPaymentMethod()}

              <div className="form-info mt-3">
                <>
                  <div className="mb-3">
                    <label htmlFor="promocode" className="form-label">
                      كود الخصم
                    </label>
                    <div className="input-code position-relative">
                      <input
                        style={{
                          backgroundColor: appliedPromo ? "gray" : "white",
                          color: appliedPromo ? "white" : "black",
                          cursor: appliedPromo ? "not-allowed" : "auto",
                          pointerEvents: appliedPromo ? "none" : "auto",
                          opacity: appliedPromo ? 0.5 : 1,
                          border: appliedPromo ? "1px solid gray" : "1px solid gray",
                        }}
                        id="promocode"
                        type="text"
                        className="form-control"
                        placeholder="كود الخصم"
                        value={promocode}
                        onChange={(e) => {
                          setPromocode(e.target.value);
                        }}
                      />
                      {appliedPromo ? (
                        <button
                          className="btn-code"
                          type="button"
                          onClick={handleRemovePromocode}
                          style={{
                            backgroundColor: "transparent",
                            color: "#dc3545",
                            border: "1px dotted #dc3545",
                            fontWeight: "500",
                            transition: "all 0.3s ease",
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = "#dc3545";
                            e.currentTarget.style.color = "white";
                            e.currentTarget.style.border = "1px solid #dc3545";
                            e.currentTarget.style.fontWeight = "bold";
                            e.currentTarget.style.transition = "all 0.3s ease";
                            e.currentTarget.style.padding = "5px 10px";
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = "transparent";
                            e.currentTarget.style.color = "#dc3545";
                            e.currentTarget.style.border = "1px solid #dc3545";
                            e.currentTarget.style.fontWeight = "bold";
                            e.currentTarget.style.transition = "all 0.3s ease";
                            e.currentTarget.style.padding = "5px 5px";
                          }}>
                          إزالة
                        </button>
                      ) : (
                        <button
                          className="btn-code"
                          type="button"
                          onClick={handleApplyPromocode}
                          style={{
                            fontWeight:
                              promocode === "" || promocode === null || promocode.trim() === ""
                                ? "normal"
                                : "bold",
                          }}
                          disabled={
                            promocode === "" || promocode === null || promocode.trim() === ""
                          }>
                          تطبيق
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlTextarea1" className="form-label">
                      ملاحظات
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={5}
                      defaultValue={""}
                      placeholder="ملاحظات"
                      onChange={handleNotesChange}
                    />
                  </div>
                </>
              </div>
              <ProviderPolicy bookingDetails={bookingDetails} />
            </div>
          </div>
        </div>

        <BookingSummary
          bookingDetails={bookingDetails}
          promoDiscount={promoDiscount}
          appliedPromo={appliedPromo}
          priceAfterDiscount={priceAfterDiscount}
          setPriceAfterDiscount={setPriceAfterDiscount}
        />
      </div>
    </div>
  );
};

const BookingSummary = ({
  bookingDetails,
  promoDiscount,
  appliedPromo,
  priceAfterDiscount,
  setPriceAfterDiscount,
}) => {
  const { branchDetails } = bookingDetails;
  const cartDetails = useSelector((state) => state.customer.cartDetails);
  const allOffers = useSelector((state) => state.customer.offers);
  const dispatch = useDispatch();

  const [employee, setEmployee] = useState({});
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [offer, setOffer] = useState({});

  useEffect(() => {
    const totalPrice = cartDetails.services.reduce(
      (acc, service) => acc + parseInt(service.price),
      0,
    );
    setTotalPrice(totalPrice);
    const offerId = cartDetails.offer_id;
    if (offerId) {
      const offerDetails = allOffers.find((offer) => offer.id === offerId);
      setOffer(offerDetails);
    }
  }, [bookingDetails, cartDetails, allOffers]);

  useEffect(() => {
    if (offer.id) {
      const discountedPrice = Math.max(
        0,
        totalPrice - (totalPrice * offer.discount_percentage) / 100,
      );
      setPriceAfterDiscount(discountedPrice);
    } else {
      setPriceAfterDiscount(totalPrice);
    }
  }, [offer, totalPrice, setPriceAfterDiscount]);

  useEffect(() => {
    const finalPrice = Math.max(0, priceAfterDiscount - promoDiscount);
    dispatch(setCartTotalPrice(finalPrice));
  }, [promoDiscount, priceAfterDiscount, dispatch]);

  useEffect(() => {
    const date = getFormattedDateForBooking(bookingDetails.booking_day);
    setDate(date);
    setTime(bookingDetails.booking_time);
    setEmployee(bookingDetails.employee_info);
  }, [bookingDetails]);

  if (!bookingDetails) return <></>;
  return (
    <div className="col-12 col-lg-4">
      <div className="cost-details style-card-details">
        <div className="head-details-one d-flex  align-items-center gap-3">
          <div className="img-details">
            <img
              src={branchDetails?.photo || img}
              alt="img"
              width={"110px"}
              height={"100px"}
              className=" object-fit-cover rounded-3"
            />
          </div>

          <div className="info-content">
            <h2 className="title-info size-text-color-dark"> {branchDetails?.vendor?.name}</h2>

            <div className="location-details d-flex  align-items-center gap-1">
              <div className="icon-location">
                <img src={iconLocation} alt="icon location" width={"25px"} height={"25px"} />
              </div>
              <p className="text-location size-text-color-gray">{branchDetails?.address}</p>
            </div>
          </div>
        </div>

        <div className="main-info-cost pt-4">
          <div className="title-header">
            <h2 className="title size-text-color-dark main-text-color">تفاصيل</h2>
          </div>

          <div className="header-top-info-content pb-3">
            {cartDetails?.services?.map((service, index) => (
              <div className="head-details d-flex gap-3 justify-content-between  pt-3" key={index}>
                <div className="info-right">
                  <h2 className="title size-text-color-gray">{service.name_ar}</h2>
                  <div className="time-info size-text-color-gray pt-2">
                    {" "}
                    {service.service_time} دقيقة
                  </div>
                </div>
                <div className="num-price size-text-color-gray ">{service.price} ر.س</div>
              </div>
            ))}
          </div>

          <div className="all-head-details border-top border-bottom   pt-3 pb-1 mb-3">
            <div className="head-details d-flex gap-3 justify-content-between mb-3">
              <div className="info-right">
                <h2 className="title size-text-color-gray main-text-color">المجموع</h2>
              </div>
              <div className="num-price size-text-color-gray main-text-color">{totalPrice} ر.س</div>
            </div>
          </div>
          {offer.id && (
            <div className="head-details d-flex gap-3 justify-content-between mb-3">
              <div className="info-right">
                <h2 className="title size-text-color-dark">الخصم</h2>
                <p className="text size-text-color-gray">{offer.name_ar}</p>
              </div>
              <div className="num-price size-text-color-dark">{offer.discount_percentage} %</div>
            </div>
          )}

          {appliedPromo && (
            <div className="head-details d-flex gap-3 justify-content-between mb-3">
              <div className="info-right">
                <h2 className="title size-text-color-dark">خصم البرومو كود</h2>
                <p className="text size-text-color-gray">{appliedPromo.code}</p>
              </div>
              <div className="num-price size-text-color-dark">{promoDiscount} ر.س</div>
            </div>
          )}

          <div className="head-details d-flex gap-3 justify-content-between mb-3">
            <div className="info-right">
              <h2 className="title size-text-color-dark">الأجمالي</h2>
            </div>
            <div className="num-price size-text-color-dark">
              {Math.max(0, priceAfterDiscount - promoDiscount)} ر.س
            </div>
          </div>

          <div className="info-employee d-flex justify-content-between mt-4 align-items-center flex-wrap gap-3">
            <div className="right-info-emp d-flex  align-items-center gap-3">
              <div className="img-emp">
                <img
                  src={img}
                  alt="img employee"
                  width={"50px"}
                  height={"50px"}
                  className="object-fit-cover rounded-circle "
                />
              </div>
              <div className="info-content">
                <h2 className="name-emp size-text-color-dark">{employee?.user?.name}</h2>
                <p className="text-emp size-text-color-gray">
                  {employee?.job_title ? employee.job_title : "موظف"}
                </p>
              </div>
            </div>
          </div>

          <div className="main-date-info d-flex  align-items-center gap-3 mt-4">
            <div className="icon-date icon-style-01">
              <img src={iconCalender} alt="icon calender" />
            </div>
            <div className="time-info">
              <h2 className="date-day size-text-color-dark">{date}</h2>
              <p className="text size-text-color-gray pt-1">{time}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StepFourServices;

import { useQRCode } from "Components/BookingsInfo/DetailsOrderBooking/BookingCoastDetails/InvoicePDf";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { generatDataForVAT } from "utlis";
import "./DetailsTaxInvoices.css";

const QRComponent = (qrString) => {
  return (
    <div>
      <img src={qrString} alt="" />
    </div>
  );
};

const InvoiceHeader = ({ booking }) => {
  const calculateTax = (amount) => {
    const taxRate = booking.tax || 15;
    return (parseFloat(amount) * (taxRate / 100)).toFixed(2);
  };
  const qrFields = {
    sellerName: booking.vendor?.name || "---",
    vatNumber: booking.vendor?.tax_number?.toString() || "---",
    timestamp: booking.created_at?.split("T")[0] || "---",
    total: booking.sub_total?.toString() || "---",
    vatTotal: calculateTax(booking.sub_total)?.toString() || "---",
  };

  const qrString = generatDataForVAT(qrFields);
  const QrCode = useQRCode(qrString);
  const QrCodeComponent = QRComponent(QrCode);

  return (
    <div className="header d-flex justify-content-between align-items-start mb-5" dir="ltr">
      <div className="qr-section">{QrCodeComponent}</div>
      <div className="invoice-header text-end">
        <h1 className="invoice-title mb-4">فاتورة ضريبية مبسطة</h1>
        <div className="invoice-details">
          <div className="detail-row">
            <span className="label">رقم الفاتورة</span>
            <span className="value">{booking?.invoice_number || "STR123456"}</span>
          </div>
          <div className="detail-row">
            <span className="label">التاريخ</span>
            <span className="value">{booking?.created_at.split("T")[0] || "---"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomerInfo = ({ booking }) => (
  <div className="customer-info">
    <div className="customer-info-section">
      <h2 className="section-title mb-3 p-2">بيانات العميل</h2>
      <div className="info-content">
        <div className="info-row">
          <span className="info-label">الاسم بالكامل</span>
          <span className="info-value">{booking?.user?.name || "---"}</span>
        </div>
        <div className="info-row">
          <span className="info-label">البريد الالكتروني</span>
          <span className="info-value">{booking?.user?.email || "---"}</span>
        </div>
        <div className="info-row">
          <span className="info-label">رقم الجوال</span>
          <span className="info-value">{booking?.user?.phone || "---"}</span>
        </div>
        <div className="info-row">
          <span className="info-label">رقم الحجز</span>
          <span className="info-value">{booking?.id || "---"}</span>
        </div>
      </div>
    </div>

    <div className="customer-info-section">
      <h2 className="section-title mb-3 p-2">بيانات الحجز</h2>
      <div className="info-content">
        <div className="info-row">
          <span className="info-label">اسم الخدمة</span>
          <span className="info-value">{booking?.services?.[0]?.name_ar || "---"}</span>
        </div>
        <div className="info-row">
          <span className="info-label">تفاصيل الخدمة</span>
          <span className="info-value">{booking?.services?.[0]?.description_ar || "---"}</span>
        </div>
        <div className="info-row">
          <span className="info-label">الاجمالي</span>
          <span className="info-value">{booking?.total || "0"} ريال</span>
        </div>
      </div>
    </div>
  </div>
);

const VendorInfo = ({ booking }) => (
  <div className="vendor-info">
    <div className="row">
      <div className="vendor-info-col">
        <div className="detail-row">
          <span className="label">اسم المتجر</span>
          <span className="value">{booking?.vendor?.name || "---"}</span>
        </div>
        <div className="detail-row">
          <span className="label">عنوان المتجر</span>
          <span className="value">{booking?.branch?.address || "---"}</span>
        </div>
      </div>
      <div className="vendor-info-col">
        <div className="detail-row">
          <span className="label">رقم تسجيل ضريبة القيمة المضافة</span>
          <span className="value">{booking?.vendor?.tax_number || "---"}</span>
        </div>
      </div>
    </div>
  </div>
);

const ServicesTable = ({ booking }) => (
  <div>
    <table className="invoice-table">
      <thead>
        <tr>
          <th>المنتجات</th>
          <th>الكمية</th>
          <th>سعر الوحدة</th>
          <th>ضريبة القيمة المضافة</th>
          <th>السعر شامل ضريبة القيمة المضافة</th>
        </tr>
      </thead>
      <tbody>
        {booking?.services?.map((service, index) => (
          <tr key={index}>
            <td>{service.name_ar}</td>
            <td>1</td>
            <td>{service.price} ر.س</td>
            <td>{(service.price * 0.15).toFixed(2)} ر.س</td>
            <td>{(service.price * 1.15).toFixed(2)} ر.س</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const TotalsSection = ({ booking }) => {
  const subTotal = booking?.sub_total || 0;
  const vatAmount = (subTotal * 0.15).toFixed(2);
  const total = booking?.total || 0;

  return (
    <div className="totals-section">
      <div className="total-row">
        <span>اجمالي المبلغ الخاضع للضريبة</span>
        <span>{subTotal} ريال</span>
      </div>
      <div className="total-row">
        <span>ضريبة القيمة المضافة (15%)</span>
        <span>{vatAmount} ريال</span>
      </div>
      <div className="total-row final-total">
        <span>الاجمالي مع الضريبة (15%)</span>
        <span>{total} ريال</span>
      </div>
    </div>
  );
};

const DetailsTaxInvoices = () => {
  const { state } = useLocation();
  const booking = state.booking;

  const generatePDF = async () => {
    const invoice = document.querySelector(".invoice-container");
    const canvas = await html2canvas(invoice, {
      scale: 2,
      useCORS: true,
      logging: false,
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const imgWidth = 210; // A4 width in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    pdf.save(`invoice-${booking?.invoice_number || "STR123456"}.pdf`);
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center p-1">
        <HeaderPageInfo title={"فاتورة ضريبية"} isShowLeftContent={false} contentPageLeft={false} />
        <div className="link-back d-flex gap-3">
          <Button className="btn btn-primary color-white bg-dark" onClick={generatePDF}>
            تحميل فاتورة PDF
          </Button>
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/vendor/taxinvoices">
            عودة
          </Link>
        </div>
      </div>
      <div className="main-container d-flex flex-column align-items-center">
        <CustomerInfo booking={booking} />
        <div className="invoice-outer-container">
          <div className="invoice-container p-4  w-100">
            <div className="invoice-content">
              <InvoiceHeader booking={booking} />
              <VendorInfo booking={booking} />
              <ServicesTable booking={booking} />
              <TotalsSection booking={booking} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsTaxInvoices;

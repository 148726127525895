import { pdf } from "@react-pdf/renderer";
import { unwrapResult } from "@reduxjs/toolkit";
import InvoicePDF, {
  VendorInvoicePDF,
} from "Components/BookingsInfo/DetailsOrderBooking/BookingCoastDetails/InvoicePDf";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import TableDataInfo from "Dashboard/Components/UiDashboard/TablesData/TableDataInfo";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getSubscriptions as getAdminSubscriptions, getBooking } from "store/admin/adminActions";
import { getSubscriptions, getBooking as getVendorBooking } from "store/vendor/vendorActions";
import { formatDateToArabic, objectToQueryParams } from "utlis";

const saveFileWithBlob = (blob, suggestedName) => {
  try {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = suggestedName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    toast.success("تم حفظ الملف بنجاح");
  } catch (error) {
    console.error("Error during file save:", error);
    toast.error("فشل حفظ الملف. يرجى المحاولة مرة أخرى.");
  }
};

const customerData = [
  { id: 1, title: "رقم الجوال", key: "phone" },
  { id: 2, title: "الاسم بالكامل", key: "name" },
  { id: 3, title: "البريد الالكترونى", key: "email" },
];

const DetailsBooking = () => {
  const { id, detailsBooking } = useParams();
  const user = useSelector((state) => state.auth.user);
  const userType = user?.type;
  const isLoading = useSelector((state) => state.admin.isLoading);
  const isVendorLoading = useSelector((state) => state.vendor.isLoading);

  const [bookingInfo, setBookingInfo] = useState(null);

  const dispatch = useDispatch();

  const fetchBooking = () => {
    dispatch(getBooking(id))
      .then(unwrapResult)
      .then((res) => {
        console.log({ res: res.data });

        setBookingInfo(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const fetchVendorBooking = () => {
    console.log("--- fetch vendor booking ---");
    dispatch(getVendorBooking(detailsBooking))
      .then(unwrapResult)
      .then((res) => {
        console.log({ res: res.data });
        setBookingInfo(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    if (id) {
      fetchBooking();
    } else if (detailsBooking) {
      fetchVendorBooking();
    }
  }, [id, detailsBooking]); //eslint-disable-line

  if (!bookingInfo && !isLoading && !isVendorLoading) {
    return <Loader />;
  }

  const generateCustomerInvoice = async () => {
    try {
      const name = `${bookingInfo?.user?.first_name} ${bookingInfo?.user?.last_name}`;
      const blob = await pdf(
        <InvoicePDF booking={bookingInfo} name={name} vendorDashboardRequest={true} />,
      ).toBlob();
      saveFileWithBlob(blob, `customer_invoice_${bookingInfo.id}.pdf`);
    } catch (err) {
      console.log(err);
      toast.error("فشل إعادة الحجز. يرجى المحاولة مرة أخرى.");
    }
  };

  const generateVendorInvoice = async () => {
    try {
      const params = {
        with: ["vendor"],
        limit: 100,
        offset: 0,
        sort: "DESC",
        paginate: "true",
        field: "id",
        deleted: "undeleted",
        resource: "all",
        resource_columns: ["id", "name_ar"],
        columns: ["vendor_id", "status"],
        operand: ["=", "="],
        column_values: [bookingInfo?.vendor_id, "active"],
      };

      let commision = 0;
      const stringParam = objectToQueryParams(params);
      let vendorSubs;
      if (userType === "vendor") {
        vendorSubs = await dispatch(getSubscriptions(stringParam)).unwrap();
      } else {
        vendorSubs = await dispatch(getAdminSubscriptions(params)).unwrap();
      }
      const subsList = vendorSubs.data.data;
      const sub = subsList[0];

      if (!sub?.commission) {
        commision = 0;
      } else {
        commision = sub.commission;
      }

      const name = `${bookingInfo?.vendor?.name}`;
      const blob = await pdf(
        <VendorInvoicePDF name={name} booking={bookingInfo} vendorCommision={commision} />,
      ).toBlob();
      saveFileWithBlob(blob, `vendor_invoice_${bookingInfo.id}.pdf`);
    } catch (err) {
      console.log({ err });
      toast.error("فشل إنشاء فاتورة المزود. يرجى المحاولة مرة أخرى.");
    }
  };

  return (
    <>
      <HelmetInfo titlePage={"تفاصيل الحجز"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"الرئيسية"}
          titleInfoPage={"تفاصيل الحجز"}
          routeHomePage={id ? "/dashboard/admin/homedashboard" : "/dashboard/vendor/homedashboard"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to={id ? "/dashboard/admin/bookingsDashboard" : "/dashboard/vendor/bookingsDashboard"}>
            عودة
          </Link>
        </div>
      </div>
      <div className="basic-information-content account-info-details mt-3" data-aos="fade-up">
        {isLoading || isVendorLoading ? (
          <Loader />
        ) : (
          <div className="all-basic-info all-details-branch">
            {/* ======== START HEADER INFO ========== */}
            <div className="header-info-profile ">
              {/* ========= START CONTENT USER INFO ========== */}
              <div className="content-user-info content-info-deails-offer">
                <div className="all-info-content  d-flex">
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">رقم الحجز</h2>
                  </div>
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{bookingInfo?.service_id}</p>
                  </div>
                </div>
              </div>
              <div className="content-user-info content-info-deails-offer">
                <div className="all-info-content  d-flex">
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">حالة الحجز</h2>
                  </div>
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{bookingInfo?.status}</p>
                  </div>
                </div>
              </div>
              <div className="content-user-info content-info-deails-offer">
                <div className="all-info-content  d-flex">
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">انشاء حجز</h2>
                  </div>
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>
                      {bookingInfo?.created_at ? formatDateToArabic(bookingInfo?.created_at) : ""}
                    </p>
                  </div>
                </div>
              </div>
              <div className="content-user-info content-info-deails-offer">
                <div className="all-info-content  d-flex">
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">موعد الحجز</h2>
                  </div>
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>
                      {bookingInfo?.booking_day} {bookingInfo?.booking_time}
                    </p>
                  </div>
                </div>
              </div>
              <div className="content-user-info content-info-deails-offer">
                <div className="all-info-content  d-flex">
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">اجمالى قيمة الحجز</h2>
                  </div>
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{bookingInfo?.total}</p>
                  </div>
                </div>
              </div>
              <div className="content-user-info content-info-deails-offer">
                <div className="all-info-content  d-flex">
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">نوع الدفع</h2>
                  </div>
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{bookingInfo?.payment_way}</p>
                  </div>
                </div>
              </div>
              <div className="content-user-info content-info-deails-offer">
                <div className="all-info-content  d-flex">
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">حالة الدفع</h2>
                  </div>
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>
                      {bookingInfo?.payment_status === "1" ? "مدفوع" : "غير مدفوع"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="content-user-info content-info-deails-offer">
                <div className="all-info-content  d-flex">
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">الموظفين</h2>
                  </div>
                  <div className="main-info-one d-flex  align-items-center">
                    {/* <p className={`text`}>{bookingInfo?.payment_status}</p> */}
                    <ul className="m-0 p-0 d-flex  align-items-center flex-wrap   gap-3">
                      <li
                        key={bookingInfo?.employee_id}
                        className="d-flex  align-items-center  gap-3">
                        {/* <img
                          src={bookingInfo?.employee?.user?.image}
                          alt={bookingInfo?.employee_id}
                          width={"35px"}
                          height={"35px"}
                        /> */}
                        <span>{bookingInfo?.employee?.user?.name}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="content-user-info content-info-deails-offer">
                <div className="all-info-content  d-flex">
                  <div className="main-info-one d-flex  align-items-center">
                    <h2 className="title">الملاحظات</h2>
                  </div>
                  <div className="main-info-one d-flex  align-items-center">
                    <p className={`text`}>{bookingInfo?.notes}</p>
                  </div>
                </div>
              </div>

              <div className="main-info-book border-top border-bottom py-3 ">
                <h2 className="title fs-5 fw-medium">مزود الخدمة</h2>
                <div className="info-content d-flex gap-2 pt-3">
                  {/* <img src={iconB} alt="icon" /> */}
                  <div
                    className=" text-color-num text-decoration-underline"
                    onClick={() => {
                      generateVendorInvoice();
                    }}>
                    فاتورة مزود الخدمة
                  </div>
                </div>
              </div>

              <div className="content-user-info content-info-deails-offer mt-3">
                <h2 className="title fs-5 fw-medium text-dark pb-3">العميل</h2>
                {customerData.map((item) => {
                  return (
                    <div className="all-info-content  d-flex" key={item.id}>
                      {/* ========= START MAIN INFO ONE ========= */}
                      <div className="main-info-one d-flex  align-items-center">
                        <h2 className="title">{item.title}</h2>
                      </div>
                      {/* ========= END MAIN INFO ONE ========= */}
                      {/* ========= START MAIN INFO ONE ========= */}
                      <div className="main-info-one d-flex  align-items-center">
                        <p className={`text  ${item.classText}`}>
                          {bookingInfo?.employee.user[item.key] ?? ""}
                        </p>
                      </div>
                      {/* ========= END MAIN INFO ONE ========= */}
                    </div>
                  );
                })}

                <div className="main-info-book border-top border-bottom py-3">
                  <div className="info-content d-flex gap-2 ">
                    <div
                      className=" text-color-num text-decoration-underline "
                      onClick={() => {
                        generateCustomerInvoice();
                      }}>
                      فاتورة العميل
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-info-details-bookings">
              <div className="table-content mt-3 table-content-2">
                <TableDataInfo
                  titleTableHeader={false}
                  isHashTrue={false}
                  hashId={false}
                  dataTbody={
                    <>
                      <tr className="bg-main-color">
                        <td>الأجمالي</td>
                        <td>{bookingInfo?.total} ر.س</td>
                      </tr>
                    </>
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DetailsBooking;

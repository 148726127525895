import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listBookings } from "store/customer/customerActions";
import { setCartBookingDay, setCartBookingTime } from "store/customer/customerSlice";
import Loader from "../../../../Components/Loader/Loader";
import { default as DaySelection } from "./DateTime";
import "./StepThreeServices.css";

const StepThree_3_Services = ({
  bookingDetails,
  setBookingDetails,
  employeeInfo,
  currentVendorSettings,
  currentBranch,
}) => {
  const dispatch = useDispatch();
  const confirmationType = useSelector((state) => state.customer.cartDetails.confirmation);

  const [activeButton, setActiveButton] = useState(null);
  const [selectedDay, setSelectedDay] = useState(bookingDetails.booking_day || "");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDayBookings, setSelectedDayBookings] = useState([]); // bookings list for the selected day
  const employeeId = bookingDetails.employee_id;

  useEffect(() => {
    setBookingDetails((prev) => ({
      ...prev,
      booking_time: "",
    }));
    dispatch(setCartBookingTime(""));
  }, [bookingDetails.booking_day]);

  // get bookings list for the selected day
  useEffect(() => {
    const getSelectedDayBookingsList = async () => {
      if (!bookingDetails.booking_day) return;
      setIsLoading(true);
      const params = {
        with: ["bookingService", "bookingService.service"],
        limit: 500,
        offset: 0,
        sort: "DESC",
        paginate: "true",
        field: "id",
        deleted: "undeleted",
        resource: "all",
        resource_columns: ["id", "name_ar"],
        columns: ["is_active", "booking_day", "booking_day"],
        operand: ["=", ">=", "<="],
        column_values: ["1", bookingDetails.booking_day, bookingDetails.booking_day],
      };
      try {
        const bookings = await dispatch(listBookings(params)).unwrap();
        const currentBranchId = currentBranch.id;
        const currentVendorId = currentBranch.vendor_id;

        const filteredBookings = bookings.data.data.filter(
          (booking) =>
            booking.branch_id === currentBranchId &&
            booking.vendor_id === currentVendorId &&
            booking.employee_id === employeeId,
        );
        console.log("filteredBookings", filteredBookings);
        setSelectedDayBookings(filteredBookings);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
      setIsLoading(false);
    };
    getSelectedDayBookingsList();
  }, [bookingDetails.booking_day]);

  const [timeSlots, setTimeSlots] = useState([]);
  const employeeOfficialHours = employeeInfo?.official_hours || [];
  const employeeDays = employeeInfo?.official_hours?.map((item) => item.day) || [];

  const generateTimeSlots = useCallback(
    (start, end) => {
      const slots = [];
      let current = new Date(`2000-01-01T${start}`);
      const endTime = new Date(`2000-01-01T${end}`);
      const bookingDifference = currentVendorSettings.booking_differance;

      while (current < endTime) {
        slots.push(
          current.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
        );
        current.setMinutes(current.getMinutes() + bookingDifference);
      }

      return slots;
    },
    [bookingDetails.services, currentVendorSettings.booking_differance],
  );

  const handleDaySelect = useCallback(
    (dateInfo) => {
      setSelectedDay(dateInfo);

      const [dayDate, dayOfWeek] = dateInfo.split("*");
      const dateObj = new Date(dayDate);
      const day = String(dateObj.getDate()).padStart(2, "0");
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const year = dateObj.getFullYear();

      const formattedDate = `${year}-${month}-${day}`;
      dispatch(setCartBookingDay(formattedDate));

      setBookingDetails((prev) => ({
        ...prev,
        booking_day: formattedDate,
      }));

      const { start_time, end_time } = employeeOfficialHours.find(
        (hours) => hours.day === dayOfWeek,
      );

      if (start_time && end_time) {
        const slots = generateTimeSlots(start_time, end_time);
        setTimeSlots(slots);
      } else {
        setTimeSlots([]);
      }
    },
    [bookingDetails.booking_day, employeeOfficialHours],
  );

  const getServciceTotalTime = (service) => {
    const serviceTime = Number(service?.service_time || 0);
    const extraTime = Number(service?.extra_time || 0);
    return (serviceTime + extraTime) * 60 * 1000;
  };

  const isTimeSlotBooked = useCallback(
    (timeSlot) => {
      const { booking_day, services } = bookingDetails;
      if (!selectedDayBookings?.length || !booking_day) return { isBooked: false };

      const [selectedYear, selectedMonth, selectedDay] = booking_day.split("-").map(Number);
      const [selectedHours, selectedMinutes] = convertTo24Hour(timeSlot).split(":").map(Number);
      const startTime = new Date(
        selectedYear,
        selectedMonth - 1,
        selectedDay,
        selectedHours,
        selectedMinutes,
      );
      const service = services[0];
      const totalDurationInMilliseconds = getServciceTotalTime(service);
      const endTime = new Date(startTime.getTime() + totalDurationInMilliseconds);

      let bookingStatus = { isBooked: false, isConfirmed: false };

      selectedDayBookings.some((booking) => {
        const isActive = booking.is_active === "1";
        const isBookingSlotConfirmed = booking.confirmation === "confirmed";
        const isCancelled = booking.status === "canceled";
        // const isCompleted = booking.status === "compleated";

        if (isCancelled) return false;
        // if (isCompleted) return false;

        const [bookingHours, bookingMinutes] = booking.booking_time.split(":").map(Number);
        const bookingStartTime = new Date(
          selectedYear,
          selectedMonth - 1,
          selectedDay,
          bookingHours,
          bookingMinutes,
        );

        const bookingService = booking.booking_service?.[0]?.service;
        if (!bookingService) return false;

        const bookingDuration =
          (Number(bookingService.service_time || 0) + Number(bookingService.extra_time || 0)) *
          60 *
          1000;
        const bookingEndTime = new Date(bookingStartTime.getTime() + bookingDuration);

        const hasOverlap =
          (startTime < bookingEndTime && startTime >= bookingStartTime) ||
          (endTime > bookingStartTime && endTime <= bookingEndTime) ||
          (startTime <= bookingStartTime && endTime >= bookingEndTime);

        if (hasOverlap) {
          if (confirmationType === "confirmed") {
            if (isBookingSlotConfirmed) {
              bookingStatus = { isBooked: true, isConfirmed: true };
              return true;
            }
          } else {
            bookingStatus = { isBooked: true, isConfirmed: isBookingSlotConfirmed };
            return true;
          }
        }
        return false;
      });

      return bookingStatus;
    },
    [selectedDayBookings, bookingDetails.booking_day, bookingDetails.services, confirmationType],
  );

  const handleTimeSlotSelect = (buttonId, time) => {
    const bookingStatus = isTimeSlotBooked(time);
    const canBook =
      !bookingStatus.isBooked || (confirmationType === "confirmed" && !bookingStatus.isConfirmed);

    if (!canBook) return;

    const convertedTime = convertTo24Hour(time);
    setActiveButton(buttonId);
    setBookingDetails((prev) => ({
      ...prev,
      booking_time: convertedTime,
    }));
    dispatch(setCartBookingTime(convertedTime));
  };

  return (
    <div className="step-three-content">
      <div className="row g-3">
        <div className="col-12 col-lg-8">
          <div className="right-info-content-step">
            <div className="header-one-step">
              <h2 className="title size-text-color-dark">اختر التاريخ والوقت</h2>
            </div>

            <DaySelection
              selectedDay={selectedDay}
              employeeDays={employeeDays}
              setSelectedDay={handleDaySelect}
            />

            <div className="right-main-info pt-4">
              <div className="button-check-pay pt-3 border-bottom pb-3">
                {isLoading ? (
                  <Loader className="size-text-color-dark" />
                ) : (
                  <>
                    <div className="time-slots-grid">
                      {timeSlots.map((timeSlot, index) => {
                        const bookingStatus = isTimeSlotBooked(timeSlot);

                        // When booking is NOT confirmed:
                        // - Disable if slot is booked (regardless of confirmation status)
                        const isNotConfirmedBookingBlocked =
                          confirmationType === "not_confirmed" && bookingStatus.isBooked;

                        // When booking IS confirmed:
                        // - Only disable if slot has a CONFIRMED booking
                        // - Allow booking if slot is not confirmed
                        const isConfirmedBookingBlocked =
                          confirmationType === "confirmed" && bookingStatus.isConfirmed;

                        // Combine both conditions
                        const isDisabled =
                          isNotConfirmedBookingBlocked || isConfirmedBookingBlocked;

                        // Update the display logic for booking status
                        const bookingStatusText = bookingStatus.isConfirmed
                          ? "محجوز (مؤكد)"
                          : "محجوز (غير مؤكد)";

                        // Ensure slot is not disabled if confirmationType is 'confirmed' and slot is 'not_confirmed'
                        const isSlotAvailable =
                          confirmationType === "confirmed" && !bookingStatus.isConfirmed;

                        return (
                          <div
                            key={index}
                            className={`time-slot-button ${
                              activeButton === index ? "active" : ""
                            } ${isDisabled && !isSlotAvailable ? "disabled" : ""}`}
                            onClick={() =>
                              (!isDisabled || isSlotAvailable) &&
                              handleTimeSlotSelect(index, timeSlot)
                            }
                            style={{
                              cursor: isDisabled && !isSlotAvailable ? "not-allowed" : "pointer",
                              opacity: isDisabled && !isSlotAvailable ? 0.6 : 1,
                            }}>
                            <div className="time-text">{timeSlot}</div>
                            {activeButton === index && !bookingStatus.isBooked && (
                              <div className="icon-check">
                                <FontAwesomeIcon icon={faCheck} />
                              </div>
                            )}
                            {bookingStatus.isBooked && (
                              <span
                                className={`text-center text-sm ${
                                  bookingStatus.isConfirmed ? "text-danger" : "text-warning"
                                }`}>
                                {bookingStatusText}
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const convertTo24Hour = (time) => {
  const [timePart, modifier] = time.split(" ");
  let [hours, minutes] = timePart.split(":").map(Number);
  if (modifier === "PM" && hours !== 12) {
    hours += 12;
  }
  if (modifier === "AM" && hours === 12) {
    hours = 0;
  }
  const formatted = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  return formatted;
};

export default StepThree_3_Services;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  error: null,

  profileInfo: {},

  bookingsList: [],
  wishList: [],
  servicesList: [],
  serviceProviders: [],
  offers: [],
  vendorsBranches: [],
  employeesPerBranch: {},
  categories: [],
  sections: [],
  assignedRandmonRatings: [],

  cartDetails: {
    vendor_id: "",
    branch_id: "",
    services: [], // list of services ids
    offer_id: "",
    promocode_id: "",
    totalPrice: 0,
    discount: 0,
    subTotalPrice: 0,
    attentdance: 0,
    booking_day: "",
    employee_id: "",
    booking_time: "",
    booking_date: "",
    payment_way: "",
    notes: "",
    confirmation: "",
  },

  homePageSearchResults: {
    services: [],
    vendors: [],
    offers: [],
  }, // search results from home page

  vendorSettings: {},
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setProfileInfo: (state, action) => {
      state.profileInfo = action.payload;
    },
    setBookingsList: (state, action) => {
      state.bookingsList = action.payload;
    },

    // wish list
    setWishList: (state, action) => {
      state.wishList = action.payload;
    },
    addItemToWishList: (state, action) => {
      state.wishList = [...state.wishList, action.payload];
    },
    removeItemFromWishList: (state, action) => {
      state.wishList = state.wishList.filter((item) => item.id !== action.payload);
    },

    // service providers
    setServiceProviders: (state, action) => {
      state.serviceProviders = action.payload;
    },
    setServicesList: (state, action) => {
      state.servicesList = action.payload;
    },
    setOffers: (state, action) => {
      state.offers = action.payload;
    },
    setVendorsBranches: (state, action) => {
      state.vendorsBranches = action.payload;
    },
    setEmployeesPerBranch: (state, action) => {
      state.employeesPerBranch = action.payload;
    },
    setSections: (state, action) => {
      state.sections = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCart: (state, action) => {
      state.cartDetails = action.payload;
    },
    setCartServices: (state, action) => {
      const existingSvcs = state.cartDetails.services;
      const doesExist = existingSvcs.some((service) => service.id === action.payload.id);
      if (!doesExist) {
        state.cartDetails.services = [...state.cartDetails.services, action.payload];
      }
    },
    setAssignedRandmonRatings: (state, action) => {
      state.assignedRandmonRatings = action.payload;
    },
    removeCartService: (state, action) => {
      state.cartDetails.services = state.cartDetails.services.filter(
        (service) => service.id !== action.payload,
      );
    },
    setCartOffer: (state, action) => {
      state.cartDetails.offer_id = action.payload;
    },
    removeCartOffer: (state) => {
      state.cartDetails.offer_id = null;
    },
    setCartPromocode: (state, action) => {
      state.cartDetails.promocode_id = action.payload;
    },
    setCartPromoDiscount: (state, action) => {
      state.cartDetails.promoDiscount = action.payload;
    },
    setCartTotalPrice: (state, action) => {
      state.cartDetails.totalPrice = action.payload;
    },
    setCartDiscount: (state, action) => {
      state.cartDetails.discount = action.payload;
    },
    setCartSubTotalPrice: (state, action) => {
      state.cartDetails.subTotalPrice = action.payload;
    },
    setCartAttentdance: (state, action) => {
      state.cartDetails.attentdance = action.payload;
    },
    setCartBookingDay: (state, action) => {
      state.cartDetails.booking_day = action.payload;
    },
    setCartBookingTime: (state, action) => {
      state.cartDetails.booking_time = action.payload;
    },
    setCartNotes: (state, action) => {
      state.cartDetails.notes = action.payload;
    },
    setCartConfirmation: (state, action) => {
      state.cartDetails.confirmation = action.payload;
    },
    setCartBranchId: (state, action) => {
      state.cartDetails.branch_id = action.payload;
    },
    setCartPaymentWay: (state, action) => {
      state.cartDetails.payment_way = action.payload;
    },
    setCartEmployeeId: (state, action) => {
      state.cartDetails.employee_id = action.payload;
    },
    clearCart: (state) => {
      state.cartDetails = initialState.cartDetails;
    },
    setHomePageSearchResults: (state, action) => {
      state.homePageSearchResults = action.payload;
    },
    setVendorSettings: (state, action) => {
      state.vendorSettings = action.payload;
    },
  },
});
export const {
  setProfileInfo,
  setBookingsList,
  setWishList,
  setServiceProviders,
  clearError,
  setIsLoading,
  setServicesList,
  setOffers,
  setVendorsBranches,
  setEmployeesPerBranch,
  setCategories,
  setEmployees,
  setCart,
  setHomePageSearchResults,
  setCartServices,
  setCartOffer,
  setCartPromocode,
  setCartTotalPrice,
  setCartDiscount,
  setCartSubTotalPrice,
  setCartAttentdance,
  setCartNotes,
  setCartBookingDay,
  setCartBookingTime,
  setCartConfirmation,
  setCartBranchId,
  setCartPaymentWay,
  removeCartOffer,
  removeCartService,
  setCartEmployeeId,
  clearCart,
  setVendorSettings,
  setAssignedRandmonRatings,
  addItemToWishList,
  removeItemFromWishList,
  setSections,
} = customerSlice.actions;
export default customerSlice.reducer;

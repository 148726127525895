import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmployees, getVendorCustomer, getVendorSettings } from "store/vendor/vendorActions";
import {
  addCustomerToCart,
  addEmployeeToCart,
  setPaymentMethod as addPaymentMethodToCart,
  setBranchId,
} from "store/vendor/vendorSlice";

export const useBookingForm = () => {
  const dispatch = useDispatch();
  const cartDetails = useSelector((state) => state.vendor.cartDetails);
  const user = useSelector((state) => state.auth.user);
  const branches = useSelector((state) => state.vendor.branches);

  const [slotDifference, setSlotDifference] = useState(10);
  const [allEmployees, setAllEmployees] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [workingDays, setWorkingDays] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [availableEmployees, setAvailableEmployees] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  // Fetch vendor settings
  useEffect(() => {
    const fetchVendorSettings = async () => {
      try {
        const res = await dispatch(getVendorSettings({})).unwrap();
        setSlotDifference(res.data.booking_differance);
      } catch (error) {
        console.error("Failed to fetch vendor settings:", error);
      }
    };

    fetchVendorSettings();
  }, [dispatch]);

  // Handle branch selection effects
  useEffect(() => {
    if (selectedBranch) {
      dispatch(setBranchId(selectedBranch.id));
      setEmployee(null);
      setWorkingDays([]);
      setSelectedDate(null);
      setAvailableTimeSlots([]);

      // Filter employees for selected branch
      if (cartDetails.services && cartDetails.services.length > 0) {
        console.log("services: ", cartDetails.services);
        const branchEmployees = allEmployees.filter((emp) =>
          emp.branches.some((branch) => branch.id === selectedBranch.id),
        );

        const employeesForServices = cartDetails.services.reduce((availableEmps, service) => {
          const employeeIds = service.serviceDetails.employees.map((emp) => emp.id);
          return branchEmployees.filter((emp) => employeeIds.includes(emp.id));
        }, []);

        setAvailableEmployees(employeesForServices);
      }
    }
  }, [selectedBranch, dispatch, allEmployees, cartDetails.services]);

  // Handle employee selection effects
  useEffect(() => {
    if (employee) {
      dispatch(addEmployeeToCart(employee));
      const employeeWorkingDays = employee.official_hours || [];
      setWorkingDays(employeeWorkingDays);
      setSelectedDate(null);
      setAvailableTimeSlots([]);
    }
  }, [employee, dispatch]);

  // Handle customer and payment method selection effects
  useEffect(() => {
    if (customer) dispatch(addCustomerToCart(customer));
    if (paymentMethod) dispatch(addPaymentMethodToCart(paymentMethod));
  }, [customer, paymentMethod, dispatch]);

  // Generate time slots
  const generateTimeSlots = useCallback(
    (date, start, end) => {
      if (!date) return [];

      const slots = [];
      let current = new Date(`2000-01-01T${start}`);
      const endTime = new Date(`2000-01-01T${end}`);

      while (current < endTime) {
        const timeSlot = current.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        });

        const hasConflict = employee?.bookings?.some((booking) => {
          const bookingDate = new Date(booking.booking_day).toISOString().split("T")[0];
          const selectedDate = new Date(date).toISOString().split("T")[0];

          if (bookingDate !== selectedDate || booking.confirmation !== "confirmed") return false;

          const bookedServiceDetails = booking.services[0];
          const bookedSvcDuration = Number(bookedServiceDetails.service_time);
          const extraTime = Number(bookedServiceDetails.extra_time);
          const bookingStartTime = new Date(`2000-01-01T${booking.booking_time}`);
          const bookingEndTime = new Date(
            bookingStartTime.getTime() + bookedSvcDuration * 60000 + extraTime * 60000,
          );
          const slotStartTime = new Date(`2000-01-01T${timeSlot}`);
          return slotStartTime >= bookingStartTime && slotStartTime < bookingEndTime;
        });

        slots.push({ time: timeSlot, hasConflict });
        current.setMinutes(current.getMinutes() + slotDifference);
      }
      return slots;
    },
    [employee, slotDifference],
  );

  // Handle date selection
  const handleDateChange = useCallback(
    (date) => {
      setSelectedDate(date);
      const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "short" }).toLowerCase();
      const daySchedule = workingDays.find((day) => day.day === dayOfWeek);

      if (daySchedule) {
        const slots = generateTimeSlots(date, daySchedule.start_time, daySchedule.end_time);
        setAvailableTimeSlots(slots);
      } else {
        setAvailableTimeSlots([]);
      }
    },
    [workingDays, generateTimeSlots],
  );

  // Handle cart changes
  useEffect(() => {
    if (!cartDetails.services) return;

    if (cartDetails.services.length === 0) {
      setAvailableEmployees([]);
      setEmployee(null);
      setCustomer(null);
      setPaymentMethod(null);
      setActiveButton(null);
      setSelectedDate(null);
      return;
    }

    // If branch is selected, filter employees for that branch
    if (selectedBranch) {
      const branchEmployees = allEmployees.filter((emp) =>
        emp.branches.some((branch) => branch.id === selectedBranch.id),
      );

      const employeesForServices = cartDetails.services.reduce((availableEmps, service) => {
        const employeeIds = service.serviceDetails.employees.map((emp) => emp.id);
        return branchEmployees.filter((emp) => employeeIds.includes(emp.id));
      }, []);

      setAvailableEmployees(employeesForServices);
    }
  }, [cartDetails, allEmployees, selectedBranch]);

  // Fetch employees and customers
  const fetchEmployeesAndCustomers = useCallback(async () => {
    try {
      if (allEmployees.length === 0) {
        const employeesParams = {
          limit: 100,
          offset: 1,
          sort: "DESC",
          paginate: "true",
          field: "id",
          deleted: "undeleted",
          resource: "all",
          with: [
            "user",
            "vendor",
            "bookings",
            "branches",
            "services",
            "breakHours",
            "officialHours",
            "bookings",
            "bookings.services",
          ],
          columns: ["is_active", "vendor_id"],
          operand: ["=", "="],
          column_values: ["1", user.model_id],
        };
        const employeesResult = await dispatch(getEmployees(employeesParams));
        setAllEmployees(unwrapResult(employeesResult).data.data);
      }

      if (allCustomers.length === 0) {
        const customersResult = await dispatch(getVendorCustomer({}));
        setAllCustomers(unwrapResult(customersResult).data.data);
      }
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  }, [dispatch, allEmployees.length, allCustomers.length, user]);

  useEffect(() => {
    fetchEmployeesAndCustomers();
  }, [fetchEmployeesAndCustomers]);

  const handleSelectPayment = useCallback((buttonId, method) => {
    setActiveButton(buttonId);
    setPaymentMethod(method);
  }, []);

  return {
    formState: {
      employee,
      customer,
      paymentMethod,
      activeButton,
      selectedDate,
      selectedBranch,
    },
    handlers: {
      setEmployee,
      setCustomer,
      setPaymentMethod,
      handleDateChange,
      handleSelectPayment,
      setSelectedBranch,
      resetForm: () => {
        setEmployee(null);
        setCustomer(null);
        setPaymentMethod(null);
        setActiveButton(null);
        setSelectedBranch(null);
      },
    },
    availableTimeSlots,
    availableEmployees,
    allCustomers,
    allEmployees,
    workingDays,
    branches,
  };
};

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { removeOfferCart } from "store/vendor/vendorSlice";

export const CartServices = ({ services, onRemoveService, offer }) => {
  const dispatch = useDispatch();
  if (!services || services.length === 0) return null;

  const removeItemFromCart = (serviceId) => {
    onRemoveService(serviceId);
    if (offer) {
      dispatch(removeOfferCart(offer.offerId));
    }
  };

  return (
    <div className="all-details-products border-bottom pb-3 border-2 pt-3">
      {services.map((item, index) => {
        const isOffer = item.isOffer;
        return (
          <div
            key={index}
            className="details-product-info d-flex justify-content-between align-items-center gap-2 flex-wrap mt-3">
            <div className="right-details">
              <h2 className="title">{item.titleService}</h2>
              {isOffer && (
                <div className="offer-details">
                  <span className="text-danger">خصم {offer.offerDiscount}%</span>
                </div>
              )}
              <p className="price pt-2">{item.price} ر.س</p>
            </div>
            <div className="buttons-action d-flex align-items-center gap-3">
              <div
                onClick={() => removeItemFromCart(item.serviceId)}
                className="icon-trash text-danger cursor-pointer-1 fs-5">
                <FontAwesomeIcon icon={faTrash} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

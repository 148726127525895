import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MediumModal } from "Components/CustomModal/CustomModal";
import SectionTitle from "Components/SectionTitle/SectionTitle";
import BadgeText from "Components/Ui/BadgeText/BadgeText";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import locationIcon from "../../../assets/images/icons/location.svg";
import imgCard from "../../../assets/images/products/01.png";
import Products from "../Products/Products";

const SpecialOffersHome = ({ offers }) => {
  const categories = useSelector((state) => state.customer.categories);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const handleCloseModal = () => setSelectedOffer(null);
  const navigate = useNavigate();
  return (
    <Products newClassection={"products-offer"}>
      <SectionTitle
        title={"عروض حصرية"}
        text={"افضل العروض بأسعار تنافسية"}
        dataAos={"fade-left"}
      />
      <div className="main-products" data-aos="fade-up">
        <Swiper
          slidesPerView={5}
          spaceBetween={20}
          modules={[Navigation]}
          navigation={true}
          loop={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          className="mySwiper">
          {offers &&
            offers.map((offer, index) => {
              const percentage = parseInt(offer.discount_percentage);
              return (
                <SwiperSlide key={index} style={{ minHeight: "30vh" }}>
                  <div onClick={() => setSelectedOffer(offer)}>
                    <OfferCard
                      bgColorBadge={"red-badge"}
                      textBadge={`${percentage !== 0 ? `${percentage}%` : "مجانا"}`}
                      newClassBadge={"badge-product"}
                      imgCard={offer?.vendor?.photo ?? imgCard}
                      cardTitle={offer.name_ar || ""}
                      locationIcon={locationIcon}
                      textLocation={offer.branch_address}
                      isIconFavorite={false}
                      category={categories.find((category) => category.id === offer.category_id)}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>

      <MediumModal
        show={!!selectedOffer}
        onHide={handleCloseModal}
        title={selectedOffer?.name_ar || "تفاصيل العرض"}
        newClass="offer-details-modal">
        {selectedOffer && (
          <div className="offer-details p-3">
            <div className="offer-header mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-3 text-primary">{selectedOffer.name_ar}</h5>
                {selectedOffer.offerService.branches.length > 0 && (
                  <a
                    onClick={() => {
                      const branchId = selectedOffer.offerService.branches[0].id;
                      if (branchId) {
                        navigate(`/servicesproviders/${branchId}`);
                      } else {
                        console.error("لا يوجد فرع لهذا العرض");
                      }
                    }}
                    className="btn btn-outline-primary btn-sm">
                    زيارة الفرع
                  </a>
                )}
              </div>
              {selectedOffer.branch_address && (
                <div className="mt-2 text-muted small">
                  <img
                    src={locationIcon}
                    alt="location"
                    className="me-1"
                    style={{ width: "14px" }}
                  />
                  {selectedOffer.branch_address}
                </div>
              )}
            </div>

            <div className="offer-info">
              <div className="service-info mb-4 p-4 bg-light rounded-3 shadow-sm">
                <h6 className="text-primary border-bottom pb-2 mb-3">تفاصيل الخدمة</h6>
                <div className="row g-3">
                  {selectedOffer.service_price && (
                    <div className="col-md-6">
                      <div className="d-flex align-items-center">
                        <span className="text-muted">السعر الأصلي:</span>
                        <strong className="ms-2">{selectedOffer.service_price}</strong>
                      </div>
                    </div>
                  )}
                  {selectedOffer.discount_percentage && (
                    <div className="col-md-6">
                      <div className="d-flex align-items-center">
                        <span className="text-muted">نسبة الخصم:</span>
                        <strong className="ms-2 text-danger">
                          {selectedOffer.discount_percentage}%
                        </strong>
                      </div>
                    </div>
                  )}
                  {selectedOffer.final_price && (
                    <div className="col-12">
                      <div className="d-flex align-items-center mt-2 pt-2 border-top">
                        <span className="text-muted">السعر النهائي:</span>
                        <strong className="ms-2 text-success h5 mb-0">
                          {selectedOffer.final_price}
                        </strong>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </MediumModal>
    </Products>
  );
};

const OfferCard = ({
  bgColorBadge,
  textBadge,
  newClassBadge,
  isIconFavorite,
  imgCard,
  cardTitle,
}) => {
  const [activeFav, setActiveFav] = useState(false);
  const actitiveIconFav = () => {
    setActiveFav(!activeFav);
  };

  return (
    <div className="card-product">
      <BadgeText bgColorBadge={bgColorBadge} textBadge={textBadge} newClass={newClassBadge} />
      {isIconFavorite && (
        <div className={`icon-favorite ${activeFav ? "" : "active"}`} onClick={actitiveIconFav}>
          <FontAwesomeIcon icon={faHeart} />
        </div>
      )}
      <div className="img-card">
        <img src={imgCard} className="w-100 h-100 object-fit-cover" alt="img product" />
      </div>

      <div className="card-body">
        <h2 className="card-title">{cardTitle}</h2>
        <div className="main-text-info d-flex align-items-center gap-2"></div>
      </div>
    </div>
  );
};

export default SpecialOffersHome;

import { useSelector } from "react-redux";
import imgEmp from "../../../../assets/images/userImg/user.png";
import "./EmployeesList.css";

const EmployeesList = ({ currentBranch }) => {
  const allEmployees = useSelector((state) => state.customer.employeesPerBranch);
  const currentBranchEmployees = allEmployees[currentBranch.id];
  if (currentBranchEmployees && currentBranchEmployees.length === 0) return null;

  return (
    <div className="all-team-info">
      <div className="header-title">
        <h2 className="title">الفريق</h2>
      </div>

      <div
        className="main-team-info d-flex align-items-center flex-wrap gap-3"
        data-aos="fade-left">
        {currentBranchEmployees.map((employee, index) => {
          return (
            <div className="team-info-one" key={index}>
              <div className="img-employee">
                <img src={imgEmp} alt="img employee" className="object-fit-cover" />
              </div>
              <div className="info-text text-center">
                <h2 className="title">{employee.user.name}</h2>
                <p className="text-title">{employee.job_title ?? "مصفف شعر"}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EmployeesList;

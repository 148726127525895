import MainOneInfo from "../InfoBookingsDetails/MainOneInfo";

const ReservationInformation = ({ booking }) => {
  return (
    <div className="reservation-info-booking style-card-details mt-3">
      <h2 className="title-header text-size-02">بيانات الحجز</h2>

      <div className="all-reservation-booking">
        {booking.servicesList.map((service, index) => {
          return (
            <div key={index}>
              <div className="all-info-main-one pt-4 d-flex align-items-center">
                <MainOneInfo
                  title={"اسم الخدمة"}
                  textInfo={service?.service?.name_ar ?? "-"}
                  newClass={"card-info-detials-main"}
                />
                <MainOneInfo
                  title={"سعر الخدمة"}
                  textInfo={`${service?.service?.price ?? "-"} ر.س`}
                  newClass={"card-info-detials-main"}
                />
                <MainOneInfo
                  title={"مدة الخدمة"}
                  textInfo={`${service?.service?.service_time ?? "-"} دقيقة`}
                  newClass={"card-info-detials-main"}
                />
              </div>
            </div>
          );
        })}

        {booking.offer && (
          <>
            <div className="all-info-main-one pt-4 d-flex  align-items-center">
              <MainOneInfo
                title={"اسم العرض"}
                textInfo={booking.offer.name_ar}
                newClass={"card-info-detials-main"}
              />
              <MainOneInfo
                title={"سعر العرض"}
                textInfo={`${booking.offer.offer_price} ر.س`}
                newClass={"card-info-detials-main"}
              />
              <MainOneInfo
                title={"نسبة الخصم"}
                textInfo={`${booking.offer.discount_percentage}%`}
                newClass={"card-info-detials-main"}
              />
            </div>

            {/* <div className="all-info-main-one pt-4 d-flex  align-items-center">
              <MainOneInfo
                title={"عدد الخدمات"}
                textInfo={`${booking.offer.services.length} خدمات`}
                newClass={"card-info-detials-main"}
              />
            </div> */}

            <div className="info-details-content pt-4">
              <h2 className="title text-size pb-2">وصف العرض</h2>
              <p className="text text-size-02 lh-lg">
                {booking.offer.description_ar ?? " لا يوجد تفاصيل"}
              </p>
            </div>
          </>
        )}

        {booking.promocode_value && (
          <div className="all-info-main-one pt-4 d-flex align-items-center">
            {/* <MainOneInfo
              title={"كود الخصم"}
              textInfo={booking.promocode_id ? `#${booking.promocode_id}` : "-"}
              newClass={"card-info-detials-main"}
            /> */}
            <MainOneInfo
              title={"قيمة الخصم"}
              textInfo={`${booking.promocode_value} ر.س`}
              newClass={"card-info-detials-main"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReservationInformation;

import { pdf } from "@react-pdf/renderer";
import Loader from "Components/Loader/Loader";
import FileSaver from "file-saver";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { cancelBooking, createBooking, getVendorSettings } from "store/customer/customerActions";
import ModalReservationConfirmation from "../ModalsBookings/ModalReservationConfirmation";
import "./BookingCoastDetails.css";
import InvoicePDF from "./InvoicePDf";

function calculateServicesTotal(servicesList) {
  return servicesList.reduce((total, { service }) => total + parseFloat(service.price), 0);
}

function calculateOfferDiscount(total, offer, promocode) {
  if (offer) {
    return (parseFloat(offer.discount_percentage) / 100) * total;
  }
  if (promocode) {
    return parseFloat(promocode);
  }
  return 0;
}

function calculateFinalTotal(servicesTotal, discountAmount) {
  return servicesTotal - discountAmount;
}

// MAIN COMPONENT
const BookingCoastDetails = ({ booking }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!booking) {
      navigate("/bookings");
    }
  }, [booking]);

  const [showModalReservation, setShowModalReservation] = useState(false);
  const [vendorSettings, setVendorSettings] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchVendorSettings = async () => {
      setIsLoading(true);
      try {
        const res = await dispatch(getVendorSettings(booking.vendor_id)).unwrap();
        setVendorSettings(res.data);
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchVendorSettings();
  }, [booking.vendor_id]);

  const servicesTotal = calculateServicesTotal(booking.servicesList);
  const discountAmount = calculateOfferDiscount(
    servicesTotal,
    booking.offer,
    booking.promocode_value,
  );
  const finalTotal = calculateFinalTotal(servicesTotal, discountAmount);

  const hideModelReservation = () => setShowModalReservation(false);
  const user = useSelector((state) => state.auth.user);

  const generateCustomerInvoice = async () => {
    try {
      const name = `${user.first_name} ${user.last_name}`;
      const blob = await pdf(<InvoicePDF booking={booking} name={name} />).toBlob();
      return FileSaver.saveAs(blob, `invoice_${booking.id}.pdf`);
    } catch (err) {
      toast.error("فشل إعادة الحجز. يرجى المحاولة مرة أخرى.");
    }
  };

  const handleRebook = async () => {
    try {
      const rebookingData = {
        employee_id: booking.employee_id,
        booking_day: booking.booking_day,
        booking_time: `${booking.booking_time.split(":")[0]}:${booking.booking_time.split(":")[1]}`,
        attendance: 1,
        sub_total: finalTotal,
        discount: discountAmount,
        total: servicesTotal,
        payment_way: booking.payment_way,
        branch_id: booking.branch_id,
        notes: booking.notes,
        services: booking.servicesList.map((service) => service.service_id),
        offer_id: booking.offer ? booking.offer.id : null,
      };
      const res = await dispatch(createBooking(rebookingData)).unwrap();
      hideModelReservation();
      toast.success("تم إعادة الحجز بنجاح", {
        autoClose: 1000,
        draggablePercent: 60,
      });
      navigate(`/bookings`);
    } catch (error) {
      toast.error("فشل إعادة الحجز. يرجى المحاولة مرة أخرى.");
    }
  };

  const handleCancelBooking = async () => {
    const isCancelAllowed = vendorSettings.cancel_booking;
    if (isCancelAllowed !== 1) {
      toast.error("لا يمكنك الغاء الحجز في هذا الوقت");
      return;
    }
    const differnceInMinutes = vendorSettings.calender_differance;
    const bookingTime = new Date(`${booking.booking_day} ${booking.booking_time}`);
    const currentTime = new Date();
    const timeDifference = bookingTime - currentTime;
    const minutesDifference = Math.floor(timeDifference / 60000);
    if (minutesDifference < differnceInMinutes) {
      toast.error("لا يمكنك الغاء الحجز في هذا الوقت");
      return;
    }
    dispatch(cancelBooking(booking.id))
      .unwrap()
      .then(() => {
        toast.success("تم الغاء الحجز بنجاح", {
          autoClose: 1000,
          draggablePercent: 60,
        });
        navigate(`/bookings`);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("فشل الغاء الحجز. يرجى المحاولة مرة أخرى.");
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <ModalReservationConfirmation
        showModalReservation={showModalReservation}
        hideModelReservation={hideModelReservation}
        onConfirm={handleRebook}
        title="إعادة الحجز"
        confirmText="تأكيد إعادة الحجز"
      />

      <div className="card-booking-coast style-card-details">
        <div className="title-header">
          <h2 className="title">تفاصيل</h2>
        </div>
        <div className="main-booking-details-card">
          <div className="header-top-det pb-3 border-bottom">
            {booking.servicesList.map(({ service }, index) => (
              <div key={index} className="head-details d-flex gap-3 justify-content-between pt-3">
                <div className="info-right">
                  <h2 className="title text-size">{service.name_ar}</h2>
                  <div className="time-info text-size pt-2">{service.service_time} دقيقة</div>
                </div>
                <div className="num-price text-size ">{service.price} ر.س</div>
              </div>
            ))}

            {booking.offer && (
              <div className="head-details d-flex gap-3 justify-content-between  pt-3">
                <div className="info-right">
                  <h2 className="title text-size">{booking.offer.name_ar}</h2>
                </div>
                <div className="num-price text-size ">{booking.offer.discount_percentage}%</div>
              </div>
            )}
          </div>
          <div className="center-info pb-3 border-bottom">
            <div className="head-details d-flex gap-3 justify-content-between  pt-3">
              <div className="info-right">
                <h2 className="title text-size">المجموع</h2>
              </div>
              <div className="num-price text-size ">{servicesTotal.toFixed(2)} ر.س</div>
            </div>
            {(booking.offer || booking.promocode_value) && (
              <div className="head-details discount-details d-flex gap-3 justify-content-between pt-3">
                <div className="info-right">
                  <h2 className="title text-size">
                    خصم {booking.offer ? `(${booking.offer.discount_percentage}%)` : ""}
                  </h2>
                </div>
                <div className="num-price text-size ">{discountAmount.toFixed(2)} ر.س</div>
              </div>
            )}
          </div>
          <div className="head-details d-flex gap-3 justify-content-between  pt-3">
            <div className="info-right">
              <h2 className="title text-size">الأجمالي</h2>
            </div>
            <div className="num-price text-size ">{finalTotal.toFixed(2)} ر.س</div>
          </div>

          {booking.status && (
            <>
              <div className="d-flex flex-column ">
                {/* TODO: add this again but make it go to the step service page */}
                {/* <button
                  onClick={openModalReservation}
                  className="main-btn main-outline-btn w-100 mt-3">
                  إعادة الحجز
                </button> */}
                {booking.payment_status === "1" && (
                  <button onClick={generateCustomerInvoice} className="main-btn w-100 mt-3">
                    اصدار فاتورة
                  </button>
                )}
              </div>

              {booking.payment_way === "cash" && (
                <div className="d-flex flex-column ">
                  <button
                    disabled={!vendorSettings.cancel_booking}
                    style={{
                      backgroundColor: vendorSettings.cancel_booking ? "#000" : "#ccc",
                      color: vendorSettings.cancel_booking ? "#fff" : "#000",
                      cursor: vendorSettings.cancel_booking ? "pointer" : "not-allowed",
                    }}
                    onClick={handleCancelBooking}
                    className="main-btn main-outline-btn w-100 mt-3">
                    الغاء الحجز
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BookingCoastDetails;

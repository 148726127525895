import { Loader } from "lucide-react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import {
  removeCartService,
  setCartBranchId,
  setCartOffer,
  setCartServices,
} from "store/customer/customerSlice";
import CardHeadDetails from "./CardHeadDetails";

const ServicesFilter = ({
  currentServices,
  currentOffers,
  currentBranch,
  isLoading,
  vendorSections,
}) => {
  const [selectedSection, setSelectedSection] = useState(null);
  const [servicesList, setServicesList] = useState([]);
  const [offersList, setOffersList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (selectedSection == null && currentServices && currentServices.length > 0) {
      setServicesList(currentServices);
    }
    if (selectedSection == null && currentOffers && currentOffers.length > 0) {
      setOffersList(currentOffers);
    }
  }, [currentServices, currentOffers, selectedSection]);

  const handleSectionSelect = (section) => {
    setSelectedSection((prev) => {
      if (prev?.id === section.id) {
        return null;
      }
      return section;
    });

    const servicesList = currentServices.filter((service) => service?.section_id === section.id);
    setServicesList(servicesList);

    const offersList = currentOffers.filter(
      (offer) => offer?.offerService?.section_id === section.id,
    );
    setOffersList(offersList);
  };

  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => setShowAll((prev) => !prev);

  if (isLoading) return <Loader />;

  return (
    <section>
      {isLoading ? (
        <div className="text-center" style={{ marginTop: "20px" }}>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="services-filter-name" data-aos="fade-left">
            <h2 className="title size-text-color-dark fs-3">الخدمات</h2>
            <div className="main-buttons-serv">
              <OwlCarousel
                dir="rtl"
                className="owl-theme"
                {...carouselOptions}
                key={selectedSection?.id || "no-selection"}>
                {vendorSections
                  .filter(
                    (section) =>
                      currentServices.some((service) => service.section_id === section.id) ||
                      currentOffers.some((offer) => offer?.offerService?.section_id === section.id),
                  )
                  .map((section) => {
                    const isSelected = selectedSection?.id === section.id;
                    return (
                      <div
                        key={section.id}
                        className={`item ${isSelected ? "selected-item" : ""}`}
                        onClick={() => handleSectionSelect(section)}>
                        <button
                          className={`btn-filter-one main-btn main-outline-btn ${isSelected ? "active" : ""}`}
                          style={{
                            width: "100%",
                            padding: "12px 24px",
                            fontWeight: "600",
                            borderRadius: "8px",
                            border: isSelected
                              ? "2px solid var(--main-01-color)"
                              : "1px solid var(--gray-02-color)",
                            backgroundColor: isSelected ? "var(--main-01-color)" : "#ffffff",
                            color: isSelected ? "#ffffff" : "var(--main-01-color)",
                            boxShadow: isSelected ? "0 4px 6px -1px rgba(0, 86, 179, 0.1)" : "none",
                            transition: "all 0.2s ease",
                            cursor: "pointer",
                            fontSize: "0.95rem",
                          }}>
                          {section.name_ar}
                        </button>
                      </div>
                    );
                  })}
              </OwlCarousel>

              {servicesList.length > 0 || offersList.length > 0 ? (
                <div className="cards-bottom mt-4">
                  <div className="all-cards-details">
                    {offersList.slice(0, 5).map((offer, index) => (
                      <ItemCard
                        key={`offer-${index}`}
                        item={offer}
                        isOffer={true}
                        currentBranch={currentBranch}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                      />
                    ))}
                    {servicesList
                      .sort((a, b) => (b.featured === "1") - (a.featured === "1"))
                      .map((service, index) => (
                        <ItemCard
                          key={`svc-${index}`}
                          item={service}
                          isOffer={false}
                          currentBranch={currentBranch}
                          selectedItem={selectedItem}
                          setSelectedItem={setSelectedItem}
                        />
                      ))}
                  </div>
                  {servicesList.length > 4 && (
                    <button onClick={toggleShowAll} className="main-btn main-outline-btn">
                      {showAll ? "أقل" : "كل الخدمات"}
                    </button>
                  )}
                </div>
              ) : (
                <div className="text-center" style={{ marginTop: "30px" }}>
                  <h2>لا توجد خدمات مضافة </h2>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </section>
  );
};

const ItemCard = ({ item, isOffer, currentBranch, selectedItem, setSelectedItem }) => {
  const cartDetails = useSelector((state) => state.customer.cartDetails);
  const dispatch = useDispatch();

  const handleClick = () => {
    setSelectedItem(item);
    const isInCart = isOffer
      ? cartDetails.offer_id === item.id
      : cartDetails.services.some((service) => service.id === item.id);

    if (!isInCart) {
      if (isOffer) {
        dispatch(setCartOffer(item.id));
        if (item.offerService) {
          dispatch(setCartServices(item.offerService));
        }
      } else {
        dispatch(setCartServices(item));
      }
      dispatch(setCartBranchId(currentBranch.id));
    } else {
      if (isOffer) {
        dispatch(setCartOffer(null));
      } else {
        dispatch(removeCartService(item.id));
      }
    }
  };

  return (
    <div className="card-serv-one" onClick={handleClick}>
      <CardHeadDetails
        item={item}
        isOffer={isOffer}
        titleServ={isOffer ? `${item?.name_ar} - ${item?.price_type}` : item?.name_ar}
        timeServ={isOffer ? null : `${item?.service_time} دقيقة`}
        priceServ={isOffer ? `${item.discount_percentage}% خصم` : item?.price}
      />
    </div>
  );
};

const carouselOptions = {
  margin: 10,
  autoplay: false,
  dots: false,
  nav: true,
  rtl: true,
  responsive: {
    0: { items: 2 },
    450: { items: 3 },
    768: { items: 4 },
  },
};

export default ServicesFilter;

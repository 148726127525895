import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import PackageOfferCard from "Dashboard/Components/UiDashboard/CardPackage/PackageOfferCard";
import { Link, useLocation } from "react-router-dom";

const translations = {
  payments: {
    percentage: "اونلاين",
    cash: "كاش",
    percentage_and_cash: "اونلاين وكاش",
  },
  subscription_type: {
    subscribe: "اشتراك",
    commission: "عمولة",
    subscribe_and_commission: "اشتراك وعمولة",
  },
  type: {
    public: "عامة",
    private: "خاصة",
  },
};
const ChangePackage = () => {
  const {
    state: { allPackages },
  } = useLocation();

  const transformPackage = (packageInfo) => {
    return {
      id: packageInfo.id,
      namePackage: packageInfo.name_ar,
      text: `نوع الاشتراك: ${translations.subscription_type[packageInfo.subscription_type]}، المدة: ${packageInfo.period}، الأيام: ${packageInfo.days}`,
      price: packageInfo.days_price.toString(),
      typePrice: "ر.س",
      dateTimePackage: `المدة: ${packageInfo.days} يوم`,
      itemsList: [
        {
          id: 0,
          textItem: `العمولة: ${packageInfo.commission}%`,
          badgeTrue: false,
        },
        { id: 3, textItem: `الفروع: ${packageInfo.branches}`, badgeTrue: false },
        { id: 4, textItem: `الموظفون: ${packageInfo.employees}`, badgeTrue: false },
        { id: 5, textItem: `العملاء: ${packageInfo.customers}`, badgeTrue: false },
        {
          id: 6,
          textItem: `طرق الدفع: ${translations.payments[packageInfo.payments]}`,
          badgeTrue: false,
        },
      ],
      buttonText: "الاشتراك في الباقة",
      textEnd: packageInfo.remove_copy_right === "1" ? "بدون حقوق نشر" : "يتطلب بطاقة دفع",
    };
  };

  const itemCardPackage = allPackages.map(transformPackage);

  return (
    <>
      <HelmetInfo titlePage={"تغير / تجديد الباقة"} />
      <div className="header-info-top d-flex  justify-content-between  align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={false}
          titleInfoPage={"تغير / تجديد الباقة"}
          routeHomePage={"/dashboard/vendor/homedashboard"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/vendor/providersubscription">
            عودة
          </Link>
        </div>
      </div>

      <div className="packages-offers-page my-3" data-aos="fade-up">
        <div className="all-package">
          <div className="row">
            {itemCardPackage.map((item) => (
              <div className="col-12 col-md-6 col-lg-4" key={item.id}>
                {/* Package Card */}
                <PackageOfferCard
                  packageId={item.id}
                  nameBackage={item.namePackage}
                  textPackage={item.text}
                  pricePackage={item.price}
                  typePackagePrice={item.typePrice}
                  dateTimePackage={item.dateTimePackage}
                  textButtonSubscription={item.buttonText}
                  textEndCard={item.textEnd}>
                  {item.itemsList.map((itemL) => (
                    <li
                      key={itemL.id}
                      className="item-package-one d-flex  align-items-center  gap-2">
                      <div className="icon-chaeck-pack">
                        <FontAwesomeIcon icon={faCheck} />
                      </div>
                      {itemL.textItem}
                      {itemL.badgeTrue && (
                        <div className="badge-text-card">
                          <h2 className="text-package-color">{itemL.textBadge}</h2>
                        </div>
                      )}
                    </li>
                  ))}
                </PackageOfferCard>
                {/*  */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePackage;

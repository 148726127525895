import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { GetWathqDetails } from "store/vendor/vendorActions";

const RegisterServicesStepOne = ({ handleNext, currentStep, setRegisterData }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [commercialNo, setCommercialNo] = useState("");
  const dispatch = useDispatch();

  const onSubmit = async () => {
    if (!isChecked) {
      toast.error("يرجى الموافقة على جلب البيانات من المنصة الوطنية واثق");
      return;
    }

    try {
      console.log({ commercialNo });
      const wathResponse = await dispatch(GetWathqDetails(commercialNo)).unwrap();
      console.log({ wathResponse });
      const { crName, activities, parties, address, crNumber } = wathResponse;
      const { isic, description } = activities;
      const {
        general: { email, telephone1 },
      } = address;
      if (!description && isic.length > 0) {
        setRegisterData((prevData) => ({
          ...prevData,
          description: isic.map((item) => item.name).join(", "),
        }));
      } else if (!description) {
        setRegisterData((prevData) => ({
          ...prevData,
          description: description,
        }));
      }
      const [firstName, ...lastName] = parties[0].name.split(" ");
      const generateCommercialNo = () => {
        return Math.floor(1000000000 + Math.random() * 9000000000).toString();
      };

      setRegisterData((prevData) => ({
        ...prevData,
        // commercial_no: commercialNo,
        commercial_no: generateCommercialNo(),
        provider_name: crName,
        first_name: firstName || "",
        last_name: lastName.join(" ") || "",
        phone: telephone1 || "",
        email: email || "",
      }));

      handleNext();
    } catch (error) {
      toast.error("حدث خطأ أثناء التحقق من رقم السجل التجاري");
    }
  };

  const allowNext = useMemo(() => {
    return isChecked && commercialNo.length > 0;
  }, [isChecked, commercialNo]);

  return (
    <div className="all-step-one bg-step">
      <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label mb-3">
          رقم السجل التجارى/ الرقم الوطني الموحد <span className="text-danger fs-5">*</span>
        </label>
        <input
          type="number"
          className="form-control"
          id="exampleFormControlInput1"
          placeholder="رقم السجل التجارى/ الرقم الوطني الموحد"
          onChange={(e) => setCommercialNo(e.target.value)}
        />
      </div>
      <div className="form-check d-flex  align-items-center  gap-2 mb-3">
        <input
          className="form-check-input"
          type="checkbox"
          id="flexCheckChecked1"
          checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="flexCheckChecked1">
          السماح بجلب البيانات من المنصة الوطنية واثق
        </label>
      </div>
      <div className="button-container">
        <button
          className={`btn main-btn  btn-next ${currentStep === 3 ? "disabled" : ""}`}
          onClick={onSubmit}
          disabled={!allowNext}
          style={{
            display: currentStep !== 2 && currentStep !== 3 ? "flex" : "none",
          }}>
          التحقق
        </button>
      </div>
    </div>
  );
};

export default RegisterServicesStepOne;

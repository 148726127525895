import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";
import "./CustomModal.css";

const CustomModal = ({ show, onHide, title, children, newClass }) => {
  return (
    <Modal show={show} onHide={onHide} centered className={`modal-custom ${newClass}`}>
      <Modal.Header className="justify-content-between">
        <Modal.Title>{title}</Modal.Title>
        <Button variant="link" className="btn-close-icon" onClick={onHide}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

const MediumModal = ({ show, onHide, title, children }) => {
  return (
    <Modal show={show} onHide={onHide} centered size="md" className="modal-custom">
      <Modal.Header className="justify-content-between">
        <Modal.Title>{title}</Modal.Title>
        <Button variant="link" className="btn-close-icon" onClick={onHide}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export { MediumModal };
export default CustomModal;

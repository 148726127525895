import { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import AddDateTime from "Dashboard/Components/UiDashboard/AddDateTime/AddDateTime";
import BranchMap from "Dashboard/Components/maps/BranchMap";
import { addBranchAdmin, getVendors } from "store/admin/adminActions";
import { getCities, getCountries, getRegions } from "store/general/generalActions";
import { setCities, setCountries, setRegions } from "store/general/generalSlice";
import { formatDateToTime24 } from "utlis";

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required("ادخل اسم الفرع"),
  country_id: Yup.string().required("اختار الدولة"),
  city_id: Yup.string().required("اختار المدينة"),
  region_id: Yup.string().required("اختار المنطقة"),
  vendor_id: Yup.string().required("اختار مقدم الخدمة"),
  address: Yup.string().required("ادخل العنوان"),
  // national_address: Yup.string().required("ادخل العنوان الوطني"),
  // show_rates: Yup.string().required("تقييم الموظفين"),
  officialHours: Yup.array().of(
    Yup.object().shape({
      start_time: Yup.mixed().nullable().required("هذا الحقل مطلوب"),
      end_time: Yup.mixed().nullable().required("هذا الحقل مطلوب"),
      day: Yup.mixed().nullable().required("هذا الحقل مطلوب"),
    }),
  ),
});

const BranchAdminAdd = () => {
  const initialFormValues = {
    name: "",
    country_id: "",
    city_id: "",
    region_id: "",
    address: "",
    // national_address: "",
    show_rates: "1",
    officialHours: [{ day: null, end_time: null, start_time: null }],
    image: "",
    lat: "24.17988868422078",
    long: "46.75511275066543",
    vendor_id: "",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { cities, regions, countries } = useSelector((state) => state.general);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [citiesList, setCitiesList] = useState([]);
  const [regionsList, setRegionsList] = useState([]);

  const [formValues, setFormValues] = useState(initialFormValues);
  const [imageUploaded, setImageUploaded] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    if (vendors?.length === 0) {
      const params = {
        deleted: "undeleted", //all,deleted
        resource: "all", //all,custom
        per_page: 100,
        limit: 100,
        offset: 0,
        paginate: "true",
        sort: "DESC",
        columns: ["is_active"],
        operand: ["="],
        column_values: ["1"],
      };
      dispatch(getVendors(params))
        .unwrap()
        .then((res) => {
          const activeVendors = res.data.data.filter((item) => item.is_active === "1");
          setVendors(activeVendors);
        });
    }
  }, [dispatch, vendors]);

  const handleInputChange = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageUploaded(URL.createObjectURL(file));
      setFormValues((prev) => ({
        ...prev,
        image: file,
      }));
    }
  };

  const handleSubmit = useCallback(
    async (values) => {
      if (isSubmitting) return;
      setIsSubmitting(true);
      const formData = new FormData();
      const updatedValues = {
        ...values,
        manager_id: 2,
        lat: formValues.lat,
        long: formValues.long,
        image: formValues.image,
        vendor_id: parseInt(values.vendor_id),
      };

      Object.entries(updatedValues).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (typeof item === "object") {
              Object.entries(item).forEach(([subKey, subValue]) => {
                if (subKey.includes("time")) {
                  const valueInFormat = formatDateToTime24(subValue);
                  formData.append(`${key}[${index}][${subKey}]`, valueInFormat);
                } else {
                  formData.append(`${key}[${index}][${subKey}]`, subValue);
                }
              });
            } else {
              formData.append(`${key}[${index}]`, item);
            }
          });
        } else {
          formData.append(key, value);
        }
      });

      for (const [key, value] of formData.entries()) {
        console.log(key, value);
      }
      try {
        await dispatch(addBranchAdmin(formData)).unwrap();
        toast.success("تم الاضافه بنجاح.");
        navigate("/dashboard/admin/admin-branches");
      } catch (error) {
        Object.values(error.errors || {})
          .flat()
          .forEach(toast.error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [formValues, isSubmitting, navigate],
  );

  const fetchGeneralLocationData = useCallback(async () => {
    if (regions?.length === 0) {
      const regionsData = await dispatch(getRegions()).unwrap();
      dispatch(setRegions(regionsData.data.data));
      setRegionsList(regionsData.data.data);
    }

    if (cities?.length === 0) {
      const citiesData = await dispatch(getCities()).unwrap();
      dispatch(setCities(citiesData.data.data));
      setCitiesList(citiesData.data.data);
    }

    if (countries?.length === 0) {
      const countriesData = await dispatch(getCountries()).unwrap();
      dispatch(setCountries(countriesData.data.data));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchGeneralLocationData();
  }, [fetchGeneralLocationData]);

  const handleMapClick = (lat, long) => {
    console.log({ lat, long });

    setFormValues((prev) => ({
      ...prev,
      lat,
      long,
    }));
  };

  return (
    <>
      <HelmetInfo titlePage="اضافة فرع جديد" />
      <div className="header-info-top d-flex justify-content-between align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          titlePage="الرئيسية"
          routeHomePage="/dashboard/admin/homedashboard"
          titleInfoPage="اضافة فرع جديد"
        />
        <Link
          className="size-text-color-dark main-text-color fs-5"
          to="/dashboard/admin/admin-branches">
          عودة
        </Link>
      </div>

      <div className="form-edit-add-branches" data-aos="fade-up">
        <FormField
          initialValues={formValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}>
          <div className="row g-1 g-sm-3">
            <InputField
              label="اسم الفرع"
              name="name"
              type="text"
              placeholder="اسم الفرع"
              success
              handleOnChange={(value) => handleInputChange("name", value)}
            />

            <SelectField
              name="vendor_id"
              label="مقدم الخدمة"
              options={vendors?.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              valueSelected="مقدم الخدمة"
              handleOnChange={(value) => handleInputChange("vendor_id", value)}
            />

            <SelectField
              name="country_id"
              label="الدولة"
              options={
                countries?.map((item) => ({
                  value: item.id,
                  label: item.name_ar,
                })) || []
              }
              valueSelected="الدولة"
              handleOnChange={(value) => {
                setSelectedCountry(value);
                handleInputChange("country_id", value);
                const regionsList = regions.filter((item) => item.country_id.toString() === value);
                setRegionsList(regionsList);
                const regionsIds = regionsList.map((item) => item.id);
                const citiesList = cities.filter((item) => regionsIds.includes(item.region_id));
                setCitiesList(citiesList);
              }}
            />

            <SelectField
              name="region_id"
              label="المنطقة"
              options={
                regionsList?.map((item) => ({
                  value: item.id,
                  label: item.name_ar,
                })) || []
              }
              valueSelected="المنطقة"
              handleOnChange={(value) => handleInputChange("region_id", value)}
            />

            <SelectField
              name="city_id"
              label="المدينة"
              options={
                citiesList?.map((item) => ({
                  value: item.id,
                  label: item.name_ar,
                })) || []
              }
              valueSelected="المدينة"
              handleOnChange={(value) => handleInputChange("city_id", value)}
            />

            {/* <InputField
              label="العنوان الوطني"
              name="national_address"
              type="text"
              placeholder="العنوان الوطني"
              success
              handleOnChange={(value) => handleInputChange("national_address", value)}
            /> */}

            <InputField
              label="عنوان الفرع"
              name="address"
              type="text"
              placeholder="عنوان الفرع"
              success
              handleOnChange={(value) => handleInputChange("address", value)}
            />

            {/* <SelectField
              name="show_rates"
              label="اظهار تقيمات الموظفين"
              options={[
                { value: 1, label: "نعم" },
                { value: 0, label: "لا" },
              ]}
              valueSelected="اظهار تقيمات الموظفين"
              handleOnChange={(value) => handleInputChange("show_rates", value)}
            /> */}

            <div className="col-12 col-md-12">
              <div className="main-time-work">
                <label htmlFor="officialHours" className="form-label">
                  ساعات العمل الرسمية
                </label>
                <AddDateTime
                  name="officialHours"
                  handleOnChange={(index, key, value) => {
                    setFormValues((prev) => {
                      const updatedOfficialHours = [...prev.officialHours];
                      updatedOfficialHours[index] = {
                        ...updatedOfficialHours[index],
                        [key]: key.includes("time") ? formatDateToTime24(value) : value,
                      };
                      return { ...prev, officialHours: updatedOfficialHours };
                    });
                  }}
                />
              </div>
            </div>

            <div className="col-12 col-sm-12 mt-4">
              <label htmlFor="image" className="form-label">
                الصورة الرئيسية
              </label>
              <input
                type="file"
                title="تحميل الصورة"
                accept="image/*"
                onChange={handleFileChange}
              />
              {imageUploaded && (
                <img
                  src={imageUploaded}
                  alt="Uploaded main branch image"
                  width="170px"
                  height="120px"
                  style={{ display: "block", marginTop: "10px" }}
                />
              )}
            </div>

            <BranchMap lat={formValues.lat} long={formValues.long} onMapClick={handleMapClick} />
          </div>
          <button type="submit" className="main-btn btn-submit px-5 mt-4" disabled={isSubmitting}>
            {isSubmitting ? "جاري الحفظ..." : "حفظ"}
          </button>
        </FormField>
      </div>
    </>
  );
};

export default BranchAdminAdd;

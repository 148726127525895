import { useState } from "react";

import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BadgeText from "../BadgeText/BadgeText";
import "./CardProduct.css";

const ProviderCard = ({
  bgColorBadge,
  textBadge,
  newClassBadge,
  isIconFavorite,
  imgCard,
  cardTitle,
  locationIcon,
  textLocation,
}) => {
  const [activeFav, setActiveFav] = useState(false);
  const actitiveIconFav = () => {
    setActiveFav(!activeFav);
  };

  return (
    <div className="card-product">
      <BadgeText bgColorBadge={bgColorBadge} textBadge={textBadge} newClass={newClassBadge} />
      {isIconFavorite && (
        <div className={`icon-favorite ${activeFav ? "" : "active"}`} onClick={actitiveIconFav}>
          <FontAwesomeIcon icon={faHeart} />
        </div>
      )}
      <div className="img-card">
        <img src={imgCard} className="w-100 h-100 object-fit-cover" alt="img product" />
      </div>

      <div className="card-body">
        <h2 className="card-title">{cardTitle}</h2>
        <div className="main-text-info d-flex align-items-center gap-2">
          <div className="icon">
            <img src={locationIcon} alt="icon location" width={"22px"} height={"22px"} />
          </div>
          <h2 className="text-info">{textLocation}</h2>
        </div>
      </div>
    </div>
  );
};

export default ProviderCard;

import PageHelmetInfo from "Components/HelmetInfo/HelmetInfo";
import PageTilte from "Components/PageTilte/PageTilte";
import ServiceProvidersList from "Components/ServicesInfo/CardsServices/CardsServices";
import PageHeaderInfo from "Components/ServicesInfo/HeaderServices/HeaderServices";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getSearchDetails } from "store/customer/customerActions";

const ServicesProviders = () => {
  const [vendors, setVendors] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const searchProviders = useCallback(
    async (params) => {
      try {
        setIsLoading(true);
        const response = await dispatch(getSearchDetails(params)).unwrap();
        const vendors = response.data.vendors.data.filter((vendor) => vendor.is_active === "1");
        setVendors(vendors);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const searchParams = {
      paginate: "true",
      per_page: 50,
      page: 1,
      order: "DESC",
      sort: "DESC",

      deleted: "undeleted",
      resource: "all",
      with: ["vendor", "vendor.branches", "vendor.branches.services"],
      columns: ["is_active"],
      operand: ["="],
      column_values: ["1"],
    };
    searchProviders(searchParams);
  }, [searchProviders]);

  return (
    <>
      <PageHelmetInfo titlePage={"مزودي الخدمات"} />
      <PageTilte titlePage={"مزودي الخدمات"} />
      <PageHeaderInfo searchProviders={searchProviders} setIsLoading={setIsLoading} />

      {/* List of vendor branches  */}
      <ServiceProvidersList vendors={vendors} isLoading={isLoading} />
    </>
  );
};

export default ServicesProviders;

import { faClock as faClockReg } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imgProduct from "../../../../assets/images/products/01.png";
import ButtonCounter from "../ButtonCounter";

const ProductCard = ({
  item,
  handleAddSvcToCart,
  handleRemoveSvcFromCart,
  isOffer,
  handleAddOfferToCart,
  handleRemoveOfferFromCart,
  handleRemoveAllServicesFromCart,
  serviceDetails,
}) => {
  const addItemToCart = () => {
    if (isOffer === false) {
      addServiceToCart();
    } else {
      addOfferToCart();
    }
  };

  const addServiceToCart = () => {
    handleAddSvcToCart({
      serviceId: item.id,
      price: item.price,
      titleService: item.name_ar,
      item,
      isOffer: false,
      serviceDetails,
    });
  };

  const addOfferToCart = () => {
    const serviceInfo = item.service;
    const offerInfo = item.offer;

    handleRemoveAllServicesFromCart();
    handleRemoveOfferFromCart();

    const newService = {
      serviceId: serviceDetails.id,
      price: serviceDetails.price,
      titleService: serviceDetails.name_ar,
      item: serviceInfo,
      isOffer: true,
      serviceDetails,
    };

    const newOffer = {
      isOffer: true,
      offerId: offerInfo.id,
      offerPrice: offerInfo.offer_price,
      offerDiscount: offerInfo.discount_percentage,
      offerTitle: offerInfo.name_ar,
      item: offerInfo,
      offerServiceId: serviceInfo.id,
    };

    handleAddSvcToCart(newService);
    handleAddOfferToCart(newOffer);
  };

  const removeItemFromCart = () => {
    if (isOffer === false) {
      handleRemoveSvcFromCart(item.id);
    } else {
      handleRemoveOfferFromCart(item.id);
      handleRemoveAllServicesFromCart();
    }
  };

  const renderItem = () => {
    if (isOffer === false) return renderService();
    return renderOffer();
  };

  const renderOffer = () => {
    const serviceDetails = item.service;
    const offerDetails = item.offer;

    if (serviceDetails) {
      return (
        <div className="card-product-category" data-aos="fade-down">
          <div className="img-product">
            <img src={imgProduct} alt="img product" />
          </div>
          <div className="info-content">
            <h2 className="title">{serviceDetails.name_ar ?? "--"}</h2>

            <div className="time-add d-flex align-items-center gap-2">
              <div className="icon-clock">
                <FontAwesomeIcon icon={faClockReg} />
              </div>
              <h2 className="text-time">{serviceDetails.service_time} دقيقة</h2>
            </div>
            <div className="offer-details">
              {offerDetails.discount_percentage > 0 ? (
                <div className="discount-badge text-danger">
                  خصم {offerDetails.discount_percentage}%
                </div>
              ) : (
                <div className="discount-badge text-danger">لا يوجد خصم</div>
              )}
              {serviceDetails.service_price &&
                serviceDetails.service_price !== offerDetails.offer_price && (
                  <div className="original-price text-decoration-line-through text-muted">
                    {serviceDetails.service_price} ر.س
                  </div>
                )}
            </div>
            <div className="bottom-details-pro d-flex aling-items-center justify-content-between gap-2">
              <div className="price-product">{offerDetails.offer_price} ر.س</div>
              <ButtonCounter
                serviceId={item.id}
                isOffer={isOffer}
                offerId={isOffer ? offerDetails.id : null}
                addToCart={() => addItemToCart()}
                removeCart={() => removeItemFromCart()}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const renderService = () => {
    return (
      <div className="card-product-category" data-aos="fade-up">
        <div className="img-product">
          <img src={imgProduct} alt="img product" />
        </div>

        <div className="info-content">
          <h2 className="title">{item.name_ar}</h2>
          <div className="time-add d-flex align-items-center gap-2">
            <div className="icon-clock">
              <FontAwesomeIcon icon={faClockReg} />
            </div>
            <h2 className="text-time">{item.service_time} دقيقة</h2>
          </div>

          <div className="bottom-details-pro d-flex aling-items-center justify-content-between gap-2">
            <div className="price-product">{item.price} ر.س</div>
            <ButtonCounter
              isOffer={isOffer}
              serviceId={item.id}
              addToCart={() => addItemToCart()}
              removeCart={() => removeItemFromCart()}
            />
          </div>
        </div>
      </div>
    );
  };

  return renderItem();
};

export default ProductCard;

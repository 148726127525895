import { unwrapResult } from "@reduxjs/toolkit";
import DisplayField from "Components/Forms/DisplayField";
import InputField from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderPageTitle from "Components/Ui/HeaderPageTitle/HeaderPageTitle";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addPackage } from "store/admin/adminActions";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name_ar: Yup.string().required("اسم الباقة مطلوب"),
  description_ar: Yup.string().required("وصف الباقة مطلوب"),

  days_price: Yup.number().optional().min(0, "يجب ان لا يكون اقل من 0"),
  month_price: Yup.number().optional().min(0, "يجب ان لا يكون اقل من 0"),
  quarter_price: Yup.number().optional().min(0, "يجب ان لا يكون اقل من 0"),
  half_year_price: Yup.number().optional().min(0, "يجب ان لا يكون اقل من 0"),
  year_price: Yup.number().optional().min(0, "يجب ان لا يكون اقل من 0"),
  commission: Yup.number().optional().min(0, "يجب ان لا يكون اقل من 0"),

  period: Yup.string().required("مطلوب"),
  days: Yup.number().optional().min(0, "يجب ان لا يكون اقل من 0"),

  policy: Yup.string().required("مطلوب").min(2),

  branches: Yup.number().required("مطلوب"),
  customers: Yup.number().required("مطلوب"),
  employees: Yup.number().required("مطلوب"),
  sms_messages: Yup.number().required("مطلوب").min(0, "يجب ان لا يكون اقل من 0"),
  whatsapp_messages: Yup.number().required("مطلوب").min(0, "يجب ان لا يكون اقل من 0"),

  payments: Yup.string().required("مطلوب"),
  subscription_type: Yup.string().required("مطلوب"),
  is_default: Yup.string().required("مطلوب"),
  is_active: Yup.boolean().required("مطلوب"),
});

const AddPackage = () => {
  const initialValues = {
    name_ar: "",
    name_en: "",
    description_ar: "",
    description_en: "",
    days_price: 0,
    month_price: 0,
    quarter_price: 0,
    half_year_price: 0,
    year_price: 0,
    commission: null,
    branches: null,
    customers: null,
    employees: null,
    period: null,
    days: 0,
    sms_messages: null,
    whatsapp_messages: null,
    payments: "",
    subscription_type: "",
    type: "public",
    policy: "",
    is_active: 1,
    is_default: 0,
    remove_copy_right: 1,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const calculateDays = (period) => {
    switch (period) {
      case "days":
        return 1;
      case "month":
        return 30;
      case "quarter":
        return 90;
      case "half_year":
        return 180;
      case "year":
        return 365;
      default:
        return 0;
    }
  };

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    const submissionValues = {
      ...values,
      // Copy Arabic values to English fields
      name_en: values.name_ar,
      description_en: values.description_ar,
    };

    // Set all price fields to 0 except the selected period's price
    const priceFields = {
      days: "days_price",
      month: "month_price",
      quarter: "quarter_price",
      half_year: "half_year_price",
      year: "year_price",
    };

    Object.entries(priceFields).forEach(([period, priceField]) => {
      if (values.period !== period) {
        submissionValues[priceField] = 0;
      }
    });

    // Calculate days based on period
    submissionValues.days = calculateDays(values.period);

    dispatch(addPackage(submissionValues))
      .then(unwrapResult)
      .then(() => {
        toast.success("تم الاضافة بنجاح.");
        resetForm();
        navigate("/dashboard/admin/packages");
      })
      .catch((error) => {
        setErrors(error.errors);
        toast.error("حدث خطأ ما.");
        console.log(error, "error");
      });
  };

  return (
    <>
      <HelmetInfo titlePage={"اضافة باقة"} />
      <div className="header-info-top d-flex justify-content-between align-items-center flex-wrap gap-3">
        <HeaderPageTitle
          isActiveLinkTwo={false}
          routePage={false}
          titlePage={"اضافة باقة"}
          routeHomePage={"/dashboard/admin/packages"}
          titleInfoPage={"اضافة باقة"}
        />
        <div className="link-back">
          <Link
            className="size-text-color-dark main-text-color fs-5"
            to="/dashboard/admin/packages">
            عودة
          </Link>
        </div>
      </div>

      <div className="form-edit-services" data-aos="fade-up">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ values, isSubmitting }) => (
            <Form>
              <div className="row g-1 g-sm-3">
                <div className="col-12 col-md-6">
                  <InputField
                    name="name_ar"
                    type="text"
                    placeholder={"اسم الباقة بالعربية"}
                    label={"اسم الباقة بالعربية"}
                    success
                  />
                </div>
                <div className="col-12 col-md-12">
                  <InputField
                    name="description_ar"
                    placeholder={"وصف الباقة بالعربية"}
                    label={"وصف الباقة بالعربية"}
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SelectField
                    name="period"
                    label="المدة"
                    options={[
                      { value: "days", label: "يوم" },
                      { value: "month", label: "شهر" },
                      { value: "quarter", label: "ربع سنة" },
                      { value: "half_year", label: "نصف سنة" },
                      { value: "year", label: "سنة" },
                    ]}
                  />
                </div>

                {values.period === "days" && (
                  <div className="col-12 col-md-6">
                    <InputField
                      label={" سعر الاشتراك اليومي"}
                      name="days_price"
                      type="number"
                      placeholder={" سعر الاشتراك اليومي"}
                      success
                    />
                  </div>
                )}

                {values.period === "month" && (
                  <div className="col-12 col-md-6">
                    <InputField
                      label={" سعر الاشتراك الشهري"}
                      name="month_price"
                      type="number"
                      placeholder={" سعر الاشتراك الشهري"}
                      success
                    />
                  </div>
                )}

                {values.period === "quarter" && (
                  <div className="col-12 col-md-6">
                    <InputField
                      label={" سعر الاشتراك الربع سنوي"}
                      name="quarter_price"
                      type="number"
                      placeholder={" سعر الاشتراك الربع سنوي"}
                      success
                    />
                  </div>
                )}

                {values.period === "half_year" && (
                  <div className="col-12 col-md-6">
                    <InputField
                      label={" سعر الاشتراك النصف سنوي"}
                      name="half_year_price"
                      type="number"
                      placeholder={" سعر الاشتراك النصف سنوي"}
                      success
                    />
                  </div>
                )}

                {values.period === "year" && (
                  <div className="col-12 col-md-6">
                    <InputField
                      label={" سعر الاشتراك سنوي"}
                      name="year_price"
                      type="number"
                      placeholder={" سعر الاشتراك سنوي"}
                      success
                    />
                  </div>
                )}

                {values.period && (
                  <div className="col-12 col-md-6">
                    <DisplayField
                      label={"عدد الايام"}
                      name="days"
                      type="number"
                      success
                      value={calculateDays(values.period)}
                      disabled
                    />
                  </div>
                )}

                <div className="col-12 col-md-6">
                  <InputField
                    label={"عدد الفروع"}
                    placeholder={"عدد الفروع"}
                    name="branches"
                    type="number"
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    label={"عدد العملاء"}
                    placeholder={"عدد العملاء"}
                    name="customers"
                    type="number"
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    label={"عدد الموظفين"}
                    placeholder={"عدد الموظفين"}
                    name="employees"
                    type="number"
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    label={"عدد الرسائل"}
                    placeholder={"عدد الرسائل"}
                    name="sms_messages"
                    type="number"
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    label={"عدد رسائل الواتساب"}
                    placeholder={"عدد رسائل الواتساب"}
                    name="whatsapp_messages"
                    type="number"
                    success
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SelectField
                    name="payments"
                    label="طريقة الدفع"
                    options={[
                      { value: "cash", label: "كاش" },
                      { value: "percentage", label: "اونلاين" },
                      { value: "cash_and_percentage", label: "كلاهما" },
                    ]}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <SelectField
                    name="subscription_type"
                    label="نوع الاشتراك"
                    options={[
                      { value: "subscribe", label: "اشتراك" },
                      { value: "commission", label: "عمولة" },
                      {
                        value: "subscribe_and_commission",
                        label: "اشتراك وعمولة",
                      },
                    ]}
                  />

                  {(values.subscription_type === "commission" ||
                    values.subscription_type === "subscribe_and_commission") && (
                    <div className="col-12 col-md-12">
                      <InputField
                        label={"العمولة"}
                        placeholder={"العمولة"}
                        name="commission"
                        type="number"
                        success
                      />
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <SelectField
                    name="is_default"
                    label="باقة افتراضيه؟"
                    options={[
                      { value: 1, label: "نعم" },
                      { value: 0, label: "لا" },
                    ]}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <InputField
                    name="policy"
                    type="text"
                    placeholder={"السياسة"}
                    label={"السياسة"}
                    success
                  />
                </div>
              </div>
              <button
                type="submit"
                className="main-btn btn-submit px-5 mt-4"
                disabled={isSubmitting}>
                {isSubmitting ? "جاري الحفظ..." : "حفظ"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddPackage;

import { Formik } from "formik";
import { useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addVendorPOSBooking, updateBooking } from "store/vendor/vendorActions";
import { clearCart, removeServiceFromCart } from "store/vendor/vendorSlice";
import * as Yup from "yup";
import { BookingForm } from "./components/BookingForm";
import { CartServices } from "./components/CartServices";
import { PaymentDetails } from "./components/PaymentDetails";
import { useBookingForm } from "./hooks/useBookingForm";

const validationSchema = Yup.object().shape({
  selectedBranch: Yup.number().required("يرجى اختيار الفرع"),
  selectedEmployee: Yup.number().required("يرجى اختيار الموظف"),
  selectedClient: Yup.number().required("يرجى اختيار العميل"),
  selectedPaymentMethod: Yup.string().required("يرجى اختيار طريقة الدفع"),
  bookingDate: Yup.date().required("يرجى اختيار تاريخ الحجز"),
  bookingTime: Yup.string().required("يرجى اختيار وقت الحجز"),
});

const PosCartDetails = ({ calculations }) => {
  const dispatch = useDispatch();
  const cartDetails = useSelector((state) => state.vendor.cartDetails);
  const branches = useSelector((state) => state.vendor.branches);

  const { formState, handlers, availableTimeSlots, availableEmployees, allCustomers, workingDays } =
    useBookingForm();

  const handleSubmit = async (values) => {
    try {
      const { selectedEmployee, selectedClient, selectedBranch, bookingDate, bookingTime } = values;
      const { services, payment, offer } = cartDetails;
      const { method, total, discount } = payment;
      console.log({ method, total, discount });

      const [hours, minutes] = bookingTime.split(":");
      const order = {
        employee_id: selectedEmployee,
        customer_id: selectedClient,
        services: services.map((service) => service.serviceId),
        attendance: 1,
        sub_total: offer ? parseFloat(offer.offerPrice) + parseFloat(offer.offerDiscount) : total,
        discount: parseFloat(discount),
        total: parseFloat(total),
        confirmation: "confirmed",
        status: "confirmed",
        payment_status: "1",
        payment_way: method,
        offer_id: offer?.offerId,
        branch_id: selectedBranch,
        notes: "no_notes",
        booking_day: bookingDate.toISOString().split("T")[0],
        booking_time: `${hours}:${minutes}`,
        offer_discount: parseFloat(offer?.offerDiscount),
      };

      if (!order.booking_time) {
        toast.error("يرجى اختيار وقت الحجز");
        return;
      }
      if (!order.booking_day) {
        toast.error("يرجى اختيار تاريخ الحجز");
        return;
      }
      if (!order.customer_id) {
        toast.error("يرجى اختيار العميل");
        return;
      }
      if (!order.employee_id) {
        toast.error("يرجى اختيار الموظف");
        return;
      }
      if (!order.branch_id) {
        toast.error("يرجى اختيار الفرع");
        return;
      }
      if (!order.services) {
        toast.error("يرجى اختيار الخدمات");
        return;
      }
      if (!order.payment_way) {
        toast.error("يرجى اختيار طريقة الدفع");
        return;
      }

      console.log("order: ", order);
      const result = await dispatch(addVendorPOSBooking(order)).unwrap();

      // Update payment status to paid
      const paymentUpdate = {
        id: result.data.id,
        payment_status: "1",
      };
      await dispatch(updateBooking(paymentUpdate)).unwrap();

      // handlers.resetForm();
      // dispatch(clearCart());
      toast.success("تم إضافة الطلب بنجاح");
    } catch (error) {
      console.error("Booking failed:", error);
      toast.error("حدث خطأ ما");
      dispatch(clearCart());
    }
  };

  const initialValues = useMemo(
    () => ({
      selectedBranch: "",
      selectedEmployee: "",
      selectedClient: "",
      selectedPaymentMethod: "",
      bookingDate: new Date(),
      bookingTime: "",
    }),
    [],
  );

  return (
    <div className="details-order">
      <h2 className="title-top">تفاصيل الطلب</h2>

      <CartServices
        services={cartDetails.services}
        offer={cartDetails.offer}
        onRemoveService={(id) => dispatch(removeServiceFromCart(id))}
      />

      <PaymentDetails calculations={calculations} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ setFieldValue, isValid, dirty, values }) => (
          <BookingForm
            values={values}
            setFieldValue={setFieldValue}
            formState={formState}
            handlers={handlers}
            availableTimeSlots={availableTimeSlots}
            availableEmployees={availableEmployees}
            allCustomers={allCustomers}
            workingDays={workingDays}
            isValid={isValid}
            dirty={dirty}
            branches={branches}
          />
        )}
      </Formik>
    </div>
  );
};

export default PosCartDetails;

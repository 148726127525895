export const formatDateToArabic = (dateTimeString) => {
  // Convert the input string to a Date object
  const date = new Date(dateTimeString);

  // Define options for formatting the date and time in Arabic
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    localeMatcher: "best fit",
    numberingSystem: "latn", // Arabic numerals
    calendar: "kobtic-civil", // Islamic Civil calendar
  };

  const formattedDate = new Intl.DateTimeFormat("ar", options).format(date);
  return formattedDate;
};

export const formatDateToTime24 = (dateTimeString) => {
  const date = new Date(dateTimeString);

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return formattedDate;
};

export const objectToQueryParams = (obj) => {
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        params.append(`${key}[${index}]`, item);
      });
    } else {
      params.append(key, value);
    }
  }

  return params.toString();
};

export const convertTimeToDate = (timeString) => {
  const [hours, minutes] = timeString?.split(":").map(Number) || [0, 0];
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
};

export const getFormattedDateForBooking = (booking_day) => {
  const day = new Date(booking_day);
  const month = day.toLocaleString("ar-EG", { month: "long" });
  const dayOfMonth = day.getDate();
  const year = day.getFullYear();
  const date = `${dayOfMonth} ${month} ${year}`;
  return date;
};

export const extractOffers = (serviceProviders, servicesList) => {
  const vendorsOffers = [];
  serviceProviders.forEach(({ offers }) => {
    offers.forEach((offer) => {
      if (offer.is_active === "1") {
        const svc = servicesList.find((service) => {
          return (
            service.section_id === offer.section_id &&
            service.category_id === offer.category_id &&
            service.sub_category_id === offer.sub_category_id &&
            service.vendor_id === offer.vendor_id
          );
        });

        vendorsOffers.push({
          ...offer,
          offerService: svc,
        });
      }
    });
  });
  return vendorsOffers;
};

export const extractSections = (services) => {
  const sections = [];
  services.forEach((service) => {
    const section = sections.find((section) => section?.id === service.section_id);
    const isSectionAvtive = service.section?.is_active === "1";
    if (!section && service.section !== null && isSectionAvtive) {
      sections.push(service.section);
    }
  });
  return sections;
};

export const extractEmployees = (services) => {
  let employeesMap = {};
  services.forEach((service) => {
    const serviceEmployees = service.employees;
    if (serviceEmployees && serviceEmployees.length > 0) {
      serviceEmployees.forEach((employee) => {
        if (!employeesMap[employee.id]) {
          employeesMap[employee.id] = {
            ...employee,
          };
        }
      });
    }
  });
  return employeesMap;
};

export const extractEmployeesPerBranch = (branches, services) => {
  const branchMap = new Map(branches.map((b) => [b.id, { employees: [], idSet: new Set() }]));

  for (const service of services) {
    for (const branch of service.branches) {
      if (branch.is_active !== "1") continue;

      const branchEntry = branchMap.get(branch.id);
      if (!branchEntry) continue;

      for (const employee of service.employees) {
        if (employee.is_active === "1" && !branchEntry.idSet.has(employee.id)) {
          branchEntry.employees.push({
            ...employee,
            service_id: service.id,
            service_name: service.name_ar,
          });
          branchEntry.idSet.add(employee.id);
        }
      }
    }
  }

  return Object.fromEntries(
    Array.from(branchMap.entries()).map(([id, entry]) => [id, entry.employees]),
  );
};

export const extractCustomerOffers = (allOffers) => {
  const customerOffers = [];
  allOffers.forEach((offer) => {
    const branch = offer.offerService.branches.find((branch) => {
      return branch.vendor_id === offer.vendor_id && branch.is_active === "1";
    });

    const newOfferObj = {
      name: offer.name_ar,
      branch_name: branch?.name,
      branch_address: branch.address,
      long: branch.long,
      lat: branch.lat,
    };
    customerOffers.push(newOfferObj);
  });
  return customerOffers;
};

export const extractBranchesFromVendors = (vendors) => {
  const branchWithVendor = vendors.flatMap((vendor) =>
    vendor.branches
      .filter((branch) => branch.is_active === "1")
      .map((branch) => ({
        ...branch,
        vendor: {
          ...vendor,
        },
      })),
  );
  return branchWithVendor;
};

export const createImageBlob = (color) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    canvas.width = 100;
    canvas.height = 100;
    const ctx = canvas.getContext("2d");

    ctx.fillStyle = color || "#000000";
    ctx.fillRect(0, 0, 100, 100);

    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      "image/jpeg",
      0.95,
    ); // JPEG format with 0.95 quality
  });
};

export const formatDateDDMMYYYY = (date) => {
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = date.getFullYear();

  return `${dd}-${mm}-${yyyy}`;
};

export const extractFavoriteProductInfo = (item) => {
  const { id, is_active, vendor } = item;
  const { name, branches } = vendor;

  return {
    id,
    ...vendor,
    productId: id,
    isProductActive: is_active,
    vendorId: vendor.id,
    productName: name,
    productImage: branches[0]?.photo ?? null,
    productBranchAddress: branches[0]?.address || "لا يوجد موقع",
  };
};
export const generatDataForVAT = (qrFields) => {
  const { sellerName, vatNumber, timestamp, total, vatTotal } = qrFields;
  const convertToHex = (num) => {
    return num.toString(16);
  };

  const hexSeller = convertToHex(1) + convertToHex(sellerName.length);
  const seller = hexSeller + sellerName;

  const hexVAT = convertToHex(2) + convertToHex(vatNumber.length);
  const vat = hexVAT + vatNumber;

  const hexTime = convertToHex(3) + convertToHex(timestamp.length);
  const timeStr = hexTime + timestamp;

  const hexTotal = convertToHex(4) + convertToHex(total.length);
  const totalStr = hexTotal + total;

  const hexVATN = convertToHex(5) + convertToHex(vatTotal.length);
  const VATN = hexVATN + vatTotal;

  const tobase = seller + vat + timeStr + totalStr + VATN;

  // Encode Unicode characters to UTF-8 before base64 encoding
  return btoa(unescape(encodeURIComponent(tobase)));
};

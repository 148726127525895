import { unwrapResult } from "@reduxjs/toolkit";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getAdminBranches, getVendors } from "store/admin/adminActions";
import { setAdminBranches, setAdminVendors } from "store/admin/adminSlice";
import { getCategories, getCities, getCountries, getRegions } from "store/general/generalActions";
import { setCities, setCountries, setRegions } from "store/general/generalSlice";
import { getBranches, getOffers, getSections, getServices } from "store/vendor/vendorActions";
import {
  setBranches,
  setCategories,
  setOffers,
  setSections,
  setServices,
} from "store/vendor/vendorSlice";
import Footer from "../Footer/Footer";
import NavBarDashboard from "../NavBarDashboard/NavBarDashboard";
import SideNavBar from "../SideNavBar/SideNavBar";

const LayoutDasboard = () => {
  const user = useGetAuthUser();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    categories,
    services: vendorServices,
    offers: vendorOffers,
    sections,
    branches,
  } = useSelector((state) => state.vendor);

  const cities = useSelector((state) => state.general.cities);
  const countries = useSelector((state) => state.general.countries);
  const regions = useSelector((state) => state.general.regions);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const requests = [];
        if (!cities?.length) requests.push(dispatch(getCities()));
        if (!countries?.length) requests.push(dispatch(getCountries()));
        if (!regions?.length) requests.push(dispatch(getRegions()));
        if (requests.length === 0) return;
        const [citiesRes, countriesRes, regionsRes] = await Promise.all(
          requests.map((r) => r.unwrap()),
        );

        if (citiesRes.data?.data) dispatch(setCities(citiesRes.data.data));
        if (countriesRes.data?.data) dispatch(setCountries(countriesRes.data.data));
        if (regionsRes.data?.data) dispatch(setRegions(regionsRes.data.data));
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };

    fetchLocationData();
  }, []);

  useEffect(() => {
    localStorage.setItem("sidebarOpen", true);
  }, []);

  const [isOpen, setIsOpen] = useState(() =>
    JSON.parse(localStorage.getItem("sidebarOpen") === "true" ? "true" : "false"),
  );

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.type === "vendor" && location.pathname.includes("admin")) {
      navigate("/dashboard/vendor/homedashboard", { replace: true });
    }
  }, [location, navigate]);

  const fetchData = useCallback(
    async (action, params, setter) => {
      try {
        const res = await dispatch(action(params)).then(unwrapResult);
        dispatch(setter(res.data.data));
      } catch (err) {
        console.error(err);
      }
    },
    [dispatch],
  );

  // fetch categories
  useEffect(() => {
    if (categories.length === 0) {
      fetchData(getCategories, {}, setCategories);
    }
  }, [categories.length, fetchData]);

  // fetch services
  useEffect(() => {
    if (user?.type === "vendor" && vendorServices.length === 0) {
      fetchData(
        getServices,
        {
          with: ["vendor", "employees", "employees.user"],
          deleted: "undeleted",
          columns: ["is_active", "vendor_id"],
          operand: ["=", "="],
          column_values: ["1", user?.model_id],
        },
        setServices,
      );
    }
  }, [vendorServices.length, fetchData, user?.model_id]);

  // fetch offers
  useEffect(() => {
    if (user?.type === "vendor" && vendorOffers.length === 0) {
      fetchData(
        getOffers,
        {
          with: ["services", "vendor", "services.employees"],
          deleted: "undeleted",
          columns: ["is_active", "vendor_id"],
          operand: ["=", "="],
          column_values: ["1", user?.model_id],
        },
        setOffers,
      );
    }
  }, [vendorOffers.length, fetchData, user?.model_id]);

  // fetch branches
  useEffect(() => {
    if (user?.type === "vendor" && branches.length === 0) {
      fetchData(
        getBranches,
        {
          with: ["vendor"],
          deleted: "undeleted",
          columns: ["is_active", "vendor_id"],
          operand: ["=", "="],
          column_values: ["1", user?.model_id],
        },
        setBranches,
      );
    }
  }, [branches.length, fetchData, user?.model_id]);

  // fetch sections
  useEffect(() => {
    if (user?.type === "vendor" && sections.length === 0) {
      fetchData(
        getSections,
        {
          deleted: "undeleted",
          columns: ["is_active", "vendor_id"],
          operand: ["=", "="],
          column_values: ["1", user?.model_id],
        },
        setSections,
      );
    }
  }, [sections.length, fetchData, user?.model_id]);

  useEffect(() => {
    if (user?.type === "admin") {
      const params = {
        limit: 100,
        offset: 0,
        sort: "DESC",
        paginate: "true",
        per_page: 100,
        page: 1,
        deleted: "undeleted",
      };
      dispatch(getVendors(params))
        .then(unwrapResult)
        .then((res) => {
          const distinctVendors = res.data.data.filter(
            (vendor, index, self) => index === self.findIndex((t) => t.id === vendor.id),
          );
          dispatch(setAdminVendors(distinctVendors ?? []));
        })
        .catch((error) => {
          console.log(error, "error");
        });

      // fetch branches
      const paramsBranches = {
        with: ["vendor"],
        deleted: "undeleted",
      };
      dispatch(getAdminBranches(paramsBranches))
        .then(unwrapResult)
        .then((res) => {
          dispatch(setAdminBranches(res.data.data ?? []));
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  }, [user?.type, fetchData]);

  useEffect(() => {
    localStorage.setItem("sidebarOpen", JSON.stringify(isOpen));
  }, [isOpen]);
  const toggleSidebar = () => setIsOpen(!isOpen);
  const closeToggleSidebar = useCallback(() => {
    if (window.innerWidth <= 991) {
      setIsOpen(false);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("resize", closeToggleSidebar);
    return () => window.removeEventListener("resize", closeToggleSidebar);
  }, [closeToggleSidebar]);
  useEffect(() => {
    if (navigator.userAgent.includes("Firefox")) {
      document.body.classList.add("firefox");
    }
  }, []);

  return (
    <div className="d-flex">
      <SideNavBar isOpen={isOpen} closeToggleSidebar={closeToggleSidebar} />
      <div className={`contnet-page d-flex flex-column vh-100 ${isOpen ? "activeOpen" : ""}`}>
        <NavBarDashboard toggleSidebar={toggleSidebar} />
        <div className="px-3 pb-4">
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default LayoutDasboard;

const TeamInfo = ({ imgEmp, nameEmployee, titleJob, handleSelectEmployee, isActive }) => {
  const addActiveTeam = () => {
    handleSelectEmployee && handleSelectEmployee();
  };

  const style = {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    margin: "8px 0",
    border: isActive ? "2px solid #00dbaa" : "1px solid #eee",
    borderRadius: "10px",
    backgroundColor: isActive ? "#f8f9ff" : "white",
    cursor: "pointer",
  };

  return (
    <div style={style} onClick={addActiveTeam}>
      <img
        src={imgEmp}
        alt="employee"
        style={{
          width: "85px",
          height: "85px",
          borderRadius: "50%",
          marginRight: "12px",
          marginLeft: "12px",
        }}
      />
      <div>
        <h2 style={{ margin: 0, fontSize: "16px" }}>{nameEmployee}</h2>
        <p style={{ margin: "4px 0 0", color: "#666" }}>{titleJob}</p>
      </div>
    </div>
  );
};

export default TeamInfo;

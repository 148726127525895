import { faCreditCard, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Form } from "formik";
import DatePicker from "react-datepicker";
import CustomDropdown from "../CustomDropdown";

export const BookingForm = ({
  values,
  setFieldValue,
  formState,
  handlers,
  availableTimeSlots,
  availableEmployees,
  allCustomers,
  workingDays,
  isValid,
  dirty,
  branches,
}) => {
  const { employee, customer, activeButton, selectedDate, selectedBranch } = formState;
  const { handleDateChange, handleSelectPayment, setSelectedBranch } = handlers;

  return (
    <Form>
      <div className="select-info-content">
        {/* Branch Selection */}
        {branches && branches.length > 0 && (
          <div className="main-select-branch mb-3">
            <label htmlFor="selectedBranch" className="form-label">
              الفرع
            </label>
            <div className="main-select-option-1 d-flex align-items-center justify-content-between gap-2">
              <div className="select-one-content w-100">
                <CustomDropdown
                  key={selectedBranch?.id}
                  options={branches}
                  textSelect={selectedBranch ? selectedBranch.name : "-إختر الفرع-"}
                  isShowNumberOption={true}
                  isIdShow={true}
                  selectedOption={selectedBranch}
                  name="selectedBranch"
                  onSelect={(branch) => {
                    setFieldValue("selectedBranch", branch.id);
                    setSelectedBranch(branch);
                  }}
                  userType="branch"
                />
                <ErrorMessage
                  name="selectedBranch"
                  component="div"
                  className="error-message text-danger fs-6 fw-medium"
                />
              </div>
            </div>
          </div>
        )}

        {allCustomers.length > 0 && (
          <div className="main-select-client mb-3">
            <label htmlFor="selectedClient" className="form-label">
              العميل
            </label>
            <div className="main-select-option-1 d-flex align-items-center justify-content-between gap-2">
              <div className="select-one-content w-100">
                <CustomDropdown
                  key={customer?.id}
                  options={allCustomers}
                  textSelect={customer ? `${customer.name}  - ${customer.phone}` : "-إختر العميل-"}
                  isShowNumberOption={true}
                  isIdShow={true}
                  selectedOption={customer}
                  name="selectedClient"
                  onSelect={(option) => {
                    setFieldValue("selectedClient", option.id);
                    handlers.setCustomer(option);
                  }}
                  userType="customer"
                />
                <ErrorMessage
                  name="selectedClient"
                  component="div"
                  className="error-message text-danger fs-6 fw-medium"
                />
              </div>
            </div>
          </div>
        )}

        {selectedBranch && availableEmployees.length > 0 && (
          <div className="select-one-content w-100 mb-3">
            <label htmlFor="selectedEmployee" className="form-label">
              الموظف
            </label>
            <CustomDropdown
              key={employee?.id}
              textSelect={
                employee
                  ? `${employee.user.first_name} ${employee.user.last_name}`
                  : "-إختر الموظف-"
              }
              options={availableEmployees}
              isShowNumberOption={true}
              isIdShow={true}
              name="selectedEmployee"
              onSelect={(employee) => {
                setFieldValue("selectedEmployee", employee.id);
                handlers.setEmployee(employee);
              }}
              selectedOption={employee}
              userType="employee"
            />
            <ErrorMessage
              name="selectedEmployee"
              component="div"
              className="error-message text-danger fs-6 fw-medium"
            />
          </div>
        )}

        {employee && (
          <div className="date-time-fields mb-3 d-flex align-items-center gap-3">
            <div className="mb-3 w-100">
              <label htmlFor="bookingDate" className="form-label">
                تاريخ الحجز
              </label>
              <DatePicker
                selected={values.bookingDate}
                onChange={(date) => {
                  setFieldValue("bookingDate", date);
                  handleDateChange(date);
                }}
                className="form-control"
                dateFormat="yyyy-MM-dd"
                minDate={new Date()}
                filterDate={(date) => {
                  const day = date.toLocaleDateString("en-US", { weekday: "short" }).toLowerCase();
                  return workingDays.some((workDay) => workDay.day === day);
                }}
              />
              <ErrorMessage
                name="bookingDate"
                component="div"
                className="error-message text-danger fs-6 fw-medium"
              />
            </div>

            {selectedDate && availableTimeSlots.length > 0 && (
              <div className="mb-3 w-100">
                <label htmlFor="bookingTime" className="form-label">
                  وقت الحجز
                </label>
                <select
                  className="form-control"
                  value={values.bookingTime}
                  onChange={(e) => setFieldValue("bookingTime", e.target.value)}>
                  <option value="">اختر الوقت</option>
                  {availableTimeSlots.map((slot, index) => (
                    <option
                      key={index}
                      value={slot.time}
                      disabled={slot.hasConflict}
                      style={{ color: slot.hasConflict ? "red" : "inherit" }}>
                      {slot.time} {slot.hasConflict ? "(محجوز)" : ""}
                    </option>
                  ))}
                </select>
                <ErrorMessage
                  name="bookingTime"
                  component="div"
                  className="error-message text-danger fs-6 fw-medium"
                />
              </div>
            )}
          </div>
        )}
      </div>

      <div className="pay-info border-bottom border-2 pb-3 pt-2">
        <h2 className="title">طريقة الدفع</h2>
        <div className="buttons-pay d-flex align-items-center gap-3 mt-4">
          <button
            type="button"
            className={`main-btn main-outline-btn w-100 ${activeButton === 1 ? "active" : ""}`}
            onClick={() => {
              handleSelectPayment(1, "online");
              setFieldValue("selectedPaymentMethod", "online");
            }}>
            <FontAwesomeIcon icon={faCreditCard} />
            كارت
          </button>
          <button
            type="button"
            className={`main-btn main-outline-btn w-100 ${activeButton === 2 ? "active" : ""}`}
            onClick={() => {
              handleSelectPayment(2, "cash");
              setFieldValue("selectedPaymentMethod", "cash");
            }}>
            <FontAwesomeIcon icon={faMoneyBill} />
            نقدي
          </button>
        </div>
        <ErrorMessage
          name="selectedPaymentMethod"
          component="div"
          className="error-message text-danger fs-6 fw-medium"
        />
      </div>

      <button type="submit" className="main-btn mt-3 w-100" disabled={!isValid || !dirty}>
        تأكيد الطلب
      </button>
    </Form>
  );
};

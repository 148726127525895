import { unwrapResult } from "@reduxjs/toolkit";
import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import SelectField from "Components/Forms/SelectField";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addAdminEmployee,
  getAdminBranches,
  getAdminServices,
  getVendors,
} from "store/admin/adminActions";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("ادخل الأسم الاول"),
  last_name: Yup.string().required("ادخل الأسم الاخير"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "يجب ان يكون ارقام")
    .min(10, "يجب أن لا يقل رقم الهاتف عن 10 ارقام")
    .max(12, "يجب ألا يتجاوز رقم الهاتف 12 رقاما")
    .required("رقم الهاتف مطلوب"),
  email: Yup.string().email("ايميل خاطئ").required("هذا الحقل مطلوب"),
  service_id: Yup.number().required("ادخل الخدمات"),
  salary: Yup.string().required("ادخل الراتب"),
  job_title: Yup.string().required("ادخل المسمى الوظيفي"),
  branch_id: Yup.number().required("عنوان الفرع"),
});

const AdminAddEmployee = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [services, setServices] = useState([]);
  const [branches, setBranches] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    branch_id: "",
    start_date: "",
    end_date: "",
    salary: "",
    service_id: "",
    job_title: "",
    vendor_id: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        const [vendorsResult, servicesResult, branchesResult] = await Promise.all([
          dispatch(
            getVendors({
              page: 1,
              per_page: 500,
              deleted: "undeleted",
              resource: "all",
              paginate: "true",
              order: "DESC",
              resource_columns: ["id", "name"],
              columns: ["is_active"],
              operand: ["="],
              column_values: ["1"],
            }),
          ).unwrap(),

          dispatch(getAdminServices()).unwrap(),
          dispatch(
            getAdminBranches({
              page: 1,
              per_page: 500,
              deleted: "undeleted",
              resource: "all",
              paginate: "true",
              order: "desc",
              resource_columns: ["id", "name"],
              columns: ["is_active"],
              operand: ["="],
              column_values: ["1"],
            }),
          ).unwrap(),
        ]);
        const activeVendors =
          vendorsResult.data.data.filter((vendor) => vendor.is_active != "0") || [];
        setVendors(activeVendors);

        const vendorServices = servicesResult.data.data || [];
        setServices(vendorServices);

        const vendorBranches = branchesResult.data.data || [];
        setBranches(vendorBranches);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("حدث خطأ في تحميل البيانات");
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [dispatch]);

  useEffect(() => {
    if (formData.vendor_id) {
      const selectedVendorId = Number(formData.vendor_id);
      const vendorBranches = branches.filter((branch) => branch.vendor_id === selectedVendorId);
      setFilteredBranches(vendorBranches);
      const vendorServices = services.filter((service) => service.vendor_id === selectedVendorId);
      setFilteredServices(vendorServices);
      setFormData((prev) => ({
        ...prev,
        branch_id: "",
        service_id: "",
      }));
    } else {
      setFilteredBranches([]);
      setFilteredServices([]);
    }
  }, [formData.vendor_id, branches, services]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      if (!formData.vendor_id || !formData.branch_id || !formData.service_id) {
        toast.error("جميع الحقول مطلوبة");
        return;
      }

      const formDataObj = new FormData();

      // Append all form data
      Object.entries(formData).forEach(([key, value]) => {
        if (value != null && value !== "") {
          if (key === "service_id") {
            formDataObj.append("service_id[0]", value);
          } else {
            formDataObj.append(key, value);
          }
        }
      });

      const dummyPassword = "12345678";
      formDataObj.append("password", dummyPassword);

      formDataObj.append("password_confirmation", dummyPassword);

      formDataObj.append("officialHours[0][day]", "sun");
      formDataObj.append("officialHours[0][start_time]", "09:00");
      formDataObj.append("officialHours[0][end_time]", "18:00");
      const result = await dispatch(addAdminEmployee(formDataObj)).then(unwrapResult);
      if (result.success) {
        toast.success("تم إضافة الموظف بنجاح");
        navigate("/dashboard/admin/employees");
      } else {
        throw new Error(result.message || "فشل في إضافة الموظف");
      }
    } catch (error) {
      console.error("Error creating employee:", error);
      toast.error(error.message || "فشل في إضافة الموظف");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <HelmetInfo titlePage="إضافة موظف جديد" />
      <div className="main-header-employee" data-aos="fade-down">
        <HeaderPageInfo title="إضافة موظف جديد" />
        <Alert variant="info" className="mt-3">
          سيتم تعيين كلمة مرور افتراضية (12345678) للموظف. يمكن للموظف تغييرها لاحقاً.
        </Alert>
      </div>

      {isLoading ? (
        <div className="loader-content">
          <Loader />
        </div>
      ) : (
        <div className="form-content mt-4" data-aos="fade-up">
          <FormField
            initialValues={formData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <InputField
                  label="الاسم الأول"
                  name="first_name"
                  value={formData.first_name}
                  handleOnChange={(value) =>
                    setFormData((prev) => ({
                      ...prev,
                      first_name: value,
                    }))
                  }
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <InputField
                  label="الاسم الأخير"
                  name="last_name"
                  value={formData.last_name}
                  handleOnChange={(value) =>
                    setFormData((prev) => ({
                      ...prev,
                      last_name: value,
                    }))
                  }
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <InputField
                  type="email"
                  label="البريد الإلكتروني"
                  name="email"
                  value={formData.email}
                  handleOnChange={(value) =>
                    setFormData((prev) => ({
                      ...prev,
                      email: value,
                    }))
                  }
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <InputField
                  type="tel"
                  label="رقم الهاتف"
                  name="phone"
                  value={formData.phone}
                  handleOnChange={(value) =>
                    setFormData((prev) => ({
                      ...prev,
                      phone: value,
                    }))
                  }
                  required
                />
              </div>

              <div className="col-md-6 mb-3">
                <InputField
                  type="date"
                  label="تاريخ البدء"
                  name="start_date"
                  value={formData.start_date}
                  handleOnChange={(value) =>
                    setFormData((prev) => ({
                      ...prev,
                      start_date: value,
                    }))
                  }
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <InputField
                  type="date"
                  label="تاريخ الانتهاء"
                  name="end_date"
                  value={formData.end_date}
                  handleOnChange={(value) =>
                    setFormData((prev) => ({
                      ...prev,
                      end_date: value,
                    }))
                  }
                  required
                />
              </div>

              <div className="col-md-6 mb-3">
                <InputField
                  type="number"
                  label="الراتب"
                  name="salary"
                  value={formData.salary}
                  handleOnChange={(value) =>
                    setFormData((prev) => ({
                      ...prev,
                      salary: value,
                    }))
                  }
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <InputField
                  type="text"
                  label="المسمى الوظيفي"
                  name="job_title"
                  value={formData.job_title}
                  handleOnChange={(value) =>
                    setFormData((prev) => ({
                      ...prev,
                      job_title: value,
                    }))
                  }
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <SelectField
                  handleOnChange={(value) =>
                    setFormData((prev) => ({
                      ...prev,
                      vendor_id: value,
                    }))
                  }
                  label="المتجر"
                  name="vendor_id"
                  value={formData.vendor_id}
                  options={vendors.map((vendor) => ({
                    value: vendor.id,
                    label: vendor.name,
                  }))}
                  required
                />
              </div>

              {formData.vendor_id && (
                <>
                  <div className="col-md-6 mb-3">
                    <SelectField
                      label="الفرع"
                      name="branch_id"
                      options={filteredBranches.map((branch) => ({
                        value: branch.id,
                        label: branch.name,
                      }))}
                      handleOnChange={(value) =>
                        setFormData((prev) => ({
                          ...prev,
                          branch_id: value,
                        }))
                      }
                      required
                      disabled={!formData.vendor_id}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <SelectField
                      label="الخدمات"
                      name="service_id"
                      options={filteredServices.map((service) => ({
                        value: service.id,
                        label: service.name_ar,
                      }))}
                      handleOnChange={(value) =>
                        setFormData((prev) => ({
                          ...prev,
                          service_id: value,
                        }))
                      }
                      required
                      disabled={!formData.vendor_id}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="d-flex justify-content-start mt-4">
              <button type="submit" className="main-btn btn-submit px-5 mt-4" disabled={isLoading}>
                {isLoading ? "جاري التحميل..." : "إضافة موظف"}
              </button>
            </div>
          </FormField>
        </div>
      )}
    </>
  );
};

export default AdminAddEmployee;

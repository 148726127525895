import Loader from "Components/Loader/Loader";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addOfferToCart,
  addServiceToCart,
  removeAllServicesFromCart,
  removeOfferCart,
  removeServiceFromCart,
} from "store/vendor/vendorSlice";
import ProductCard from "./CardProductDetails";

const CategoryFilter = ({ sections, services, offers }) => {
  const dispatch = useDispatch();
  const addCartService = (service) => dispatch(addServiceToCart(service));
  const removeCartService = (serviceId) => dispatch(removeServiceFromCart(serviceId));
  const addCartOffer = (offer) => dispatch(addOfferToCart(offer));
  const removeCartOffer = (offerId) => dispatch(removeOfferCart(offerId));
  const removeAllServices = () => dispatch(removeAllServicesFromCart());

  const [selectedSection, setSelectedSection] = useState(null);
  const [showOffers, setShowOffers] = useState(false);
  const [filteredItems, setFilteredItems] = useState(services);

  useEffect(() => {
    const filterItems = () => {
      if (showOffers) {
        const offerServices = offers.map((offer) => {
          return {
            offer: offer,
            service: offer.services[0],
          };
        });
        return offerServices || [];
      }
      if (selectedSection) {
        return services.filter((item) => item.section_id === selectedSection.id);
      }
      return services;
    };

    setFilteredItems(filterItems());
  }, [services, selectedSection, offers, showOffers]);

  const handleSectionChange = (section) => {
    setSelectedSection(section);
    setShowOffers(false);
  };

  const handleOffersClick = () => {
    setShowOffers(true);
    setSelectedSection(null);
  };

  if (!sections || sections.length === 0 || services.length === 0) return <Loader />;

  return (
    <div className="main-filter-category">
      <h2 className="title-top-1">الأقسام</h2>
      <div className="all-filter-buttons">
        <div className="filetr-buttons d-flex align-items-center gap-2">
          <button
            className={`main-btn main-outline-btn ${!selectedSection && !showOffers ? "active" : ""}`}
            onClick={() => {
              setSelectedSection(null);
              setShowOffers(false);
            }}>
            جميع المنتجات
          </button>

          {sections.length &&
            sections.map((section, index) => (
              <button
                className={`main-btn main-outline-btn ${selectedSection === section ? "active" : ""}`}
                key={index}
                onClick={() => handleSectionChange(section)}>
                {section.name_ar}
              </button>
            ))}

          <button
            style={showOffers ? { border: "4px dotted white" } : { border: "4px dotted #000" }}
            className={`main-btn main-outline-btn ${showOffers ? "active" : ""}`}
            onClick={handleOffersClick}>
            العروض المتاحة
          </button>
        </div>
      </div>

      <div className="all-products mt-3">
        <div className="row g-3">
          {filteredItems?.length === 0 ? (
            <div className="col-12 text-center">
              <p className="text-muted">
                {showOffers ? "لا توجد عروض متاحة." : "لا توجد منتجات في هذا القسم."}
              </p>
            </div>
          ) : (
            filteredItems?.map((item, index) => {
              console.log("filtered item: ", item);
              return (
                <div className="col-12 col-sm-6 col-xl-4" key={index}>
                  <ProductCard
                    key={index}
                    isOffer={showOffers}
                    serviceDetails={showOffers ? item.service : item}
                    item={item}
                    handleAddSvcToCart={addCartService}
                    handleRemoveSvcFromCart={removeCartService}
                    handleAddOfferToCart={addCartOffer}
                    handleRemoveOfferFromCart={removeCartOffer}
                    handleRemoveAllServicesFromCart={removeAllServices}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryFilter;

import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import Loader from "Components/Loader/Loader";
import HeaderPageInfo from "Dashboard/Components/HeaderPageInfo/HeaderPageInfo";
import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import imgUser from "../../../assets/images/userImg/user.png";
import { getBookings, getBranches, getEmployees } from "../../../store/vendor/vendorActions";
import "./Calendar.css";
import UpdateBookingModal from "./Modals/UpdateBookingModal";

const defaultImage = imgUser;
const STARTING_HOUR = 8;
const TOTAL_HOURS = 17;

const colors = ["#FFB74D", "#81C784", "#FF8A80"];
const getBookingColor = (status) => {
  switch (status) {
    case "pending":
    case "notconfirmed":
      return colors[0];
    case "confirmed":
    case "compleated":
      return colors[1];
    case "canceled":
      return colors[2];
  }
};

const BookingCell = ({ booking, rowIndex, colIndex, onBookingUpdate }) => {
  const [showModal, setShowModal] = useState(false);
  const { booking_time, employee, booking_service } = booking;
  const service = booking_service[0].service;
  const status = booking.status;
  const paymentStatus = booking.payment_status;

  // Calculate end time
  const calculateEndTime = () => {
    const [hours, minutes] = booking_time.split(":");
    const startDate = new Date();
    startDate.setHours(parseInt(hours), parseInt(minutes), 0);
    const serviceTime = parseInt(service.service_time);
    const endDate = new Date(startDate.getTime() + serviceTime * 60000);
    return endDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const handleBookingClick = () => {
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleBookingUpdate = (updatedBooking) => {
    onBookingUpdate(updatedBooking);
  };

  return (
    <>
      <div
        onClick={handleBookingClick}
        key={`${rowIndex}-${colIndex}`}
        className="booking-cell"
        style={{
          cursor: "pointer",
          backgroundColor: getBookingColor(booking.status),
          padding: "10px",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          opacity: status === "compleated" ? 1 : 0.5,
        }}>
        <div style={{ fontSize: "12px", marginBottom: "5px" }}>
          {booking_time.substring(0, 5)} - {calculateEndTime()}
        </div>
        <div style={{ fontWeight: "bold", marginBottom: "5px" }}>{employee.user.name}</div>
        <div style={{ fontSize: "12px" }}>{service.name_ar}</div>
        <div style={{ fontSize: "12px" }}>{paymentStatus === "1" ? "مدفوع" : "غير مدفوع"}</div>
      </div>

      <UpdateBookingModal
        show={showModal}
        onHide={handleModalClose}
        booking={booking}
        onUpdate={handleBookingUpdate}
      />
    </>
  );
};

const Calendar = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const [bookings, setBookings] = useState([]);
  const [branches, setBranches] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [startDate, setStartDate] = useState(new Date().toISOString().split("T")[0]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");

  const fetchBranchesAndEmployees = useCallback(async () => {
    setIsLoading(true);
    try {
      const params_branches = {
        limit: 100,
        offset: 0,
        deleted: "undeleted",
        resource: "all",
        resource_columns: ["id", "name"],
        columns: ["vendor_id", "is_active"],
        operand: ["=", "="],
        column_values: [user?.model_id, "1"],
      };
      const branchesResponse = await dispatch(getBranches(params_branches)).unwrap();
      const branches = branchesResponse.data.data.map(({ id, name }) => ({ id, name }));
      setBranches(branches);

      const params_employees = {
        with: ["user"],
        limit: 100,
        offset: 0,
        deleted: "undeleted",
        resource: "all",
        resource_columns: ["id", "name"],
        columns: ["vendor_id", "is_active"],
        operand: ["=", "="],
        column_values: [user?.model_id, "1"],
      };
      const employeesResponse = await dispatch(getEmployees(params_employees)).unwrap();
      const employees = employeesResponse.data.data.map(({ id, name, user }) => ({
        id,
        name,
        user,
      }));
      setEmployees(employees);
    } catch (error) {
      console.error("Error fetching branches and employees:", error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchBranchesAndEmployees();
  }, [fetchBranchesAndEmployees]);

  const fetchBookings = useCallback(async () => {
    setIsLoading(true);
    const vendorId = user?.model_id;
    const filterDate = startDate;
    const [year, month, day] = filterDate.split("-");
    const bookingDay = `${year}-${month}-${day}`;

    const params = {
      with: ["branch", "bookingService.service", "employee", "employee.user"],
      limit: 100,
      offset: 0,
      sort: "DESC",
      paginate: "true",
      field: "id",
      deleted: "undeleted",
      resource: "all",
      resource_columns: ["id", "name"],
      columns: ["booking_day", "vendor_id"],
      operand: ["=", "="],
      column_values: [bookingDay, vendorId],
    };

    if (selectedBranch) {
      params.columns.push("branch_id");
      params.operand.push("=");
      params.column_values.push(selectedBranch);
    }

    if (selectedEmployee) {
      params.columns.push("employee_id");
      params.operand.push("=");
      params.column_values.push(selectedEmployee);
    }

    if (vendorId) {
      params.columns.push("vendor_id");
      params.operand.push("=");
      params.column_values.push(vendorId);
    }

    try {
      const { data } = await dispatch(getBookings(params)).unwrap();
      setBookings(data.data);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    } finally {
      setIsLoading(false);
    }
  }, [startDate, selectedBranch, selectedEmployee, dispatch, user?.model_id]);

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  const handleBookingUpdate = useCallback(() => {
    fetchBookings();
  }, [fetchBookings]);

  const timeSlots = Array.from({ length: TOTAL_HOURS }, (_, index) => {
    const hour = STARTING_HOUR + index;
    return `${hour}:00`;
  });

  return (
    <div className="calendar-container">
      <HelmetInfo titlePage={"التقويم"} />
      <HeaderPageInfo title={"التقويم"} isShowLeftContent={false} contentPageLeft={false} />

      <div className="header-search-calender pt-3 pb-4" data-aos="fade-left">
        <div className="row g-3">
          <div className="col-12 col-md-2">
            <div className="input-date-info">
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  const formatedDate = date.toISOString().split("T")[0];
                  setStartDate(formatedDate);
                }}
                className="form-control"
                placeholderText="التاريخ"
              />
              <div className="input-icon">
                <FontAwesomeIcon icon={faCalendar} />
              </div>
            </div>
          </div>

          <div className="col-12 col-md-2">
            <div className="select-input">
              <select
                className="form-select"
                value={selectedBranch}
                onChange={(e) => setSelectedBranch(e.target.value)}>
                <option value="">الفرع</option>
                {branches.map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-12 col-md-2">
            <div className="select-input">
              <select
                className="form-select"
                value={selectedEmployee}
                onChange={(e) => {
                  setSelectedEmployee(e.target.value);
                }}>
                <option value="">الموظف</option>
                {employees.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.user.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="calender">
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}>
            <Loader />
          </div>
        ) : (
          <div className="table-data-content">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    {employees.map((employee) => (
                      <th key={employee.id}>
                        <div
                          className="employee-header"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}>
                          <img
                            src={defaultImage}
                            className="img-fluid"
                            alt={" "}
                            width={"50px"}
                            height={"50px"}
                            style={{ borderRadius: "50%", marginBottom: "10px" }}
                          />
                          <span>{employee.user.name}</span>{" "}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {timeSlots.map((timeSlot, rowIndex) => (
                    <tr key={rowIndex}>
                      <th>{timeSlot}</th>
                      {employees.map((employee, colIndex) => {
                        const cellBookings = bookings.filter((book) => {
                          const bookingTime = book.booking_time.split(":")[0];
                          const timeSlotHour = timeSlot.split(":")[0];
                          const bookingEmployeeId = book.employee.id;
                          const employeeId = employee.id;
                          return (
                            parseInt(bookingTime) === parseInt(timeSlotHour) &&
                            bookingEmployeeId === employeeId
                          );
                        });
                        return (
                          <td key={colIndex}>
                            {cellBookings.map((booking, cellIndex) => (
                              <BookingCell
                                key={cellIndex}
                                booking={booking}
                                rowIndex={rowIndex}
                                colIndex={colIndex}
                                onBookingUpdate={handleBookingUpdate}
                              />
                            ))}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Calendar;

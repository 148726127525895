import SectionTitle from "Components/SectionTitle/SectionTitle";
import { useState } from "react";
import iconSearch from "../../../assets/images/icons/search.svg";
import "./HeaderServices.css";

const HeaderServices = ({ searchProviders, setIsLoading }) => {
  return (
    <div className="header-services">
      <div className="container">
        <div className="row g-3">
          <div className="col-12 col-md-4">
            <SectionTitle
              title={"مزودي الخدمات"}
              text={"احجز مع شركاء ذوي تصنيف عالٍ بأسعار تنافسية"}
              dataAos={"fade-left"}
            />
          </div>

          <div className="col-12 col-md-8">
            <div data-aos="fade-right">
              <SerachBar isText={false} searchProviders={searchProviders} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SerachBar = ({ isText, searchProviders }) => {
  const [searchText, setSearchText] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const searchParams = {
      paginate: "true",
      per_page: 200,
      page: 1,
      order: "DESC",
      sort: "id",
      deleted: "undeleted",
      resource: "all",
      with: ["vendor", "vendor.branches", "vendor.branches.services"],
      columns: ["is_active"],
      operand: ["="],
      column_values: ["1"],
    };
    if (searchText) {
      searchParams.search_text = searchText;
    }
    searchProviders(searchParams);
  };

  return (
    <>
      <div className="search-content">
        {isText && <h2 className="title-search">بحث</h2>}
        <form action="">
          <div className="main-search d-flex align-items-center flex-wrap gap-2">
            <div className="form-input-one">
              <label htmlFor="searchForm" className="form-label d-none"></label>
              <div className="input-info d-flex align-items-center">
                <div className="icon">
                  <img src={iconSearch} alt="icon search" />
                </div>
                <input
                  type="search"
                  className="form-control"
                  id="searchForm"
                  placeholder="اسم مزودى الخدمة"
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                />
              </div>
            </div>

            <button className="main-btn" type="submit" onClick={handleSubmit}>
              بحث
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default HeaderServices;

import Loader from "Components/Loader/Loader";
import CardProduct from "Components/Ui/CardProduct/ProviderCard";
import { useNavigate } from "react-router-dom";
import { extractBranchesFromVendors } from "utlis";
import locationIcon from "../../../assets/images/icons/location.svg";
import imgCard from "../../../assets/images/products/01.png";
import "./CardServices.css";

const ServiceProvidersCards = ({ vendors, isLoading }) => {
  const branches = extractBranchesFromVendors(vendors ?? []);
  const navigate = useNavigate();

  const renderProviders = () => {
    if (isLoading) {
      return <Loader />;
    } else if (branches.length === 0) {
      return <div className="text-center">لا يوجد مزودين</div>;
    } else {
      return branches.map((branch, index) => {
        return (
          <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={index}>
            <div onClick={() => navigate(`/servicesproviders/${branch.id}`)}>
              <CardProduct
                bgColorBadge={"red-badge"}
                textBadge={"50%"}
                newClassBadge={"badge-product-services"}
                isIconFavorite={false}
                imgCard={branch.photo ?? imgCard}
                cardTitle={branch.vendor.name + " - " + branch.name}
                locationIcon={locationIcon}
                textLocation={branch.address}
              />
            </div>
          </div>
        );
      });
    }
  };

  return (
    <div className="all-cards-services" style={{ minHeight: "60vh" }}>
      <div className="container">
        <div className="row g-3">{renderProviders()}</div>
      </div>
    </div>
  );
};

export default ServiceProvidersCards;
